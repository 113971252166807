import React, { useContext, useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { DEFAULTLANGUAGE, LANGUAGES } from '../../Common/Constants/constants';
import { getImageDetails } from "../../../utilities/imagesutils";
import useLangaugeStyles from "./LanguageStyles";
import { TLContext } from "../../../Context";

const Language = () => {
  const MenuProps = {
    PaperProps: {
      style: {
        position: "initial",
        transform: "translateX(232px) translateY(87px)",
        width: "196px",
        minWidth: "196px",
        maxWidth:"196px",
        overFlow: "hidden"
      },
    },
    
    "& .Mui-selected" : {
      background :"#ffffff !important "
    }
  };
  const {
    LanguageDropDown,
    LangSelectCheckBox,
    Langli,
    LangSelectMenuInput,
    DefaultPlaceholder,
    FlagImage,
    LangText,
    DefaultLang,
    DisplayVal
  } = useLangaugeStyles();
  const { Languages, SelectedLanguages,DefaultLanguages,SetLanguages, SetAddNewTranslation } = useContext(TLContext) || {};
  const [selected, setSelected] = useState([]);
  const isAllSelected =Languages.length > 0 && selected.length === SelectedLanguages.length;

  useEffect(() => {
    setSelected(SelectedLanguages);
  }, [SelectedLanguages]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === SelectedLanguages.length ? [] : SelectedLanguages);
      return;
    }
    SetAddNewTranslation(false);
    setSelected(value);
    let langFilter = [...DefaultLanguages]
    let result = langFilter.filter(o1 => value.some(o2 => o1.Name === o2));
    SetLanguages(result);
  };

  const lang = getImageDetails("English");

  return (
    SelectedLanguages && 
    <Select
      labelId="mutiple-select-label"
      multiple
      value={selected}
      displayEmpty
      className={LanguageDropDown}
      onChange={handleChange}
      MenuProps={MenuProps}
      renderValue={(selected) => {
        return <div><img className={FlagImage} src={lang.img} alt="" /> <span className={LangText}>{LANGUAGES}</span></div>;
      }}
    >
      <MenuItem className={DisplayVal} value="all" >
        <ListItemIcon>
          <Checkbox
            className={DisplayVal}
            checked={isAllSelected}
            indeterminate={
              selected.length > 0 && selected.length < SelectedLanguages.length
            }
          />
        </ListItemIcon>
      </MenuItem>
      <MenuItem className={DefaultPlaceholder} disabled value={""}>{LANGUAGES}</MenuItem>
      {SelectedLanguages.map((option) => (
        <MenuItem className={Langli} key={option} value={option} disabled={option === DEFAULTLANGUAGE}>
          <ListItemIcon>
            <Checkbox className={LangSelectCheckBox} checked={selected.indexOf(option) > -1} />
          </ListItemIcon>
          <ListItemText primary={option.charAt(0).toUpperCase() + option.slice(1).toLowerCase()} />
        </MenuItem>
      ))}
    </Select>
  );
};
export default Language;
