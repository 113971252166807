import React, { useContext } from "react";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Avatar from "@mui/material/Avatar";
import useHeaderStyles from "./HeaderStyles";
import { TLContext } from "../../Context";
import { getImageDetails } from "../../utilities/imagesutils";

const Header = (props) => {
  const {
    MuiAvatarroot,
    Wrapper,
    Container,
    Header,
    Line,
    AddUser,
    BrandSize,
    HeaderTitle,
    UserIconAndInitials,
  } = useHeaderStyles();

  const { Permission } = useContext(TLContext);
  const { firstName = "", lastName = "" } = Permission || {};
  const UserNameInitials = () => {
    if (Permission) {
      const userNameFirstNLastInitial = firstName[0] + lastName[0];
      return (
        <Avatar className={MuiAvatarroot}>{userNameFirstNLastInitial}</Avatar>
      );
    }
  };
  const Logo = getImageDetails("CarrierLogo");
  
  const reloadPage = () => {
    window.location.reload();
  }

  return (
    <header className={Header}>
      <div className={BrandSize}>
        <img id='CarrierLogo' src={Logo.img} alt='Carrier' onClick={reloadPage} />
      </div>
      <div className={HeaderTitle}>Translation Manager</div>
      <div className={UserIconAndInitials}>
        <PersonAddAltIcon className={AddUser} />
        <UserNameInitials />
      </div>
    </header>
  );
  // }
};

export default Header;
