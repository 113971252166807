import React, { useState, useContext } from "react";
import { TLContext } from "../../../Context";
import TextField from "@mui/material/TextField";
import useApplicationStyles from "./ApplicationStyles";
import {
  validateFormFields,
  defaultValidation,
  defaultValidationMessages
} from "./../../../utilities/applicationValidateutils";
import CommonDialog from "../../Common/Dialog/commonDialog";
import {
  ADDAPPLICATIONEHEADER,
  APPLICATIONPROGRESS,
  APPLICATIONSUCCESS,
  APPLICATIONFAILURE,
  APPLICATIONVALIDATE
} from "../../Common/Constants/constants";
import AddIcon from "@mui/icons-material/Add";
import { AddApplication } from "../../../assets/APIInterface";

const AddNewApplication = (props) => {
  const { AddNewApplicationOpen, Update } = props;
  const { AddNewApplication,AddNewApplicationWithError } = useApplicationStyles();
  const [ApplicationText, setApplicationText] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [ErrorKey, setErrorKey] = useState(false);

  const { Applications, SetApplications, setIsLoading, SetNotification } =
    useContext(TLContext) || {};

  const createActionsButton = () => {
    return [
      {
        id: "Yes_Validatetag",
        name: "Validate",
        onClick: onValidate
      }
    ];
  };

  const onValidate = (event) => {
    const validateMessage = validateFormFields(
      ApplicationText,
      defaultValidation,
      defaultValidationMessages
    );
    if (validateMessage === "") {
      setIsLoading(true);
      SetNotification({
        visible: true,
        statusType: "info",
        notificationText:APPLICATIONPROGRESS,
        isAutohide: true,
        showProgress: true
      });
      let ApplicationsName = {
        Name: ApplicationText
      };
      AddApplication(ApplicationsName, (QueriedApplications) => {
        let updatedAppsList = [...Applications];
        if (QueriedApplications.status === "success") {
          SetNotification({
            visible: true,
            statusType: "success",
            notificationText: APPLICATIONSUCCESS,
            isAutohide: true,
            showProgress: false
          });
          updatedAppsList.push({
            id: QueriedApplications.result.id,
            Name: QueriedApplications.result.Name
          });
          SetApplications(updatedAppsList);
          setIsLoading(false);
          setApplicationText("");
          Update(false);
        } else {
          if (
            QueriedApplications.status === "error" &&
            QueriedApplications.result != ""
          ) {
            SetNotification({
              visible: true,
              statusType: "error",
              notificationText: APPLICATIONVALIDATE,
              isAutohide: true,
              showProgress: false
            });
            setIsLoading(false);
          } else {
            SetNotification({
              visible: true,
              statusType: "error",
              notificationText: APPLICATIONFAILURE,
              isAutohide: true,
              showProgress: false
            });
            setIsLoading(false);
          }
        }
      });
    } else {
      setErrorMessage(validateMessage);
      setErrorKey(true)
    }
  };
  function handleApplicationName({ target: { value } }) {
    setErrorMessage("");
    setErrorKey(false)
    setApplicationText(value);
  }

  function onAddNewApplicationModalClose() {
    setApplicationText("");
    setErrorMessage("");
    setErrorKey(false)
    Update(false);
  }
  const DisplayApplicationData = () => {
    return (
      <div>
        <TextField
          className= {
            (ErrorKey ? AddNewApplicationWithError  :AddNewApplication)
          }
          id="addApplication"
          label="Page name"
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: false }}
          onChange={handleApplicationName}
          required
          error={ErrorKey}
          InputLabelProps={{ shrink: true }}
        />
      </div>
    );
  };
  return (
    <div>
      <CommonDialog
        open={AddNewApplicationOpen}
        translationData={DisplayApplicationData()}
        onEditTransModalClose={onAddNewApplicationModalClose}
        actionButtonList={createActionsButton()}
        title={ADDAPPLICATIONEHEADER}
        headerIcon={AddIcon}
        errorMsg={ErrorMessage}
      />
    </div>
  );
};
export default AddNewApplication;
