import React, { useState,useContext } from 'react';
import { TLContext } from "../../../Context";
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useNotificationStyles from './notificationStyles';
import { SUCCESS,ERROR,WARNING,INFO } from '../Constants/constants';
const Notifications = () => {
  const { root, container, description, infoIcon, closeIconStyles, error, success, info, warning,
    alignCenter, linerProgress } = useNotificationStyles();
    const { Notification,SetNotification } = useContext(TLContext) || {};
const { visible, statusType, notificationText, isAutohide, showProgress  } = Notification;
const closeNotification = (event, reason) => {
    if (showProgress || reason === "clickaway") {
        return;
    } else {
        SetNotification({visible : false})
    }
}

const getNotificationDetails = (notificationClass, iconComponent, notificationId) => {
    return { notificationClass, iconComponent, notificationId }
}

const getDetails = (statusType) => {
    if (statusType === SUCCESS) {
        return getNotificationDetails(success, <CheckCircleIcon fontSize="small" className={infoIcon}/>, 'SuccessNotification')
    }
    else if (statusType === ERROR) {
        return getNotificationDetails(error, <ErrorIcon fontSize="small" className={infoIcon}/>, 'ErrorNotification')
    }
    else if (statusType === WARNING) {
        return getNotificationDetails(warning, <ReportProblemOutlinedIcon fontSize="small" className={infoIcon}/>, 'WarningNotification')
    }
    else if (statusType === INFO) {
        return getNotificationDetails(info, <InfoOutlinedIcon fontSize="small" className={infoIcon}/>, 'InfoNotification')
    }
}

const notificationDetails = getDetails(statusType);
return (
    notificationDetails ?
        <Snackbar
            open={visible}
            onClose={closeNotification}
            classes={{root: root}}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center"
           }}
           autoHideDuration="6000"
        >
            <div>
                {showProgress && (<div className={linerProgress}><LinearProgress /></div>)}
                <div id={notificationDetails.notificationId} 
                className={`${notificationDetails.notificationClass} ${container}`}>
                    {notificationDetails.iconComponent}
                    <div id="notificationText" className={description}>
                        {notificationText}
                        <div id="notificationClose" className={alignCenter}>
                            <CloseIcon fontSize="small" className={closeIconStyles} onClick={closeNotification}/>
                        </div>
                    </div>
                </div>
            </div>
        </Snackbar>
    :
    null
)
}


export default Notifications;