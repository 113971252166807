import React from 'react';
import appConfig from "../../Environment/environments";
import { APPLICATIONPERMISSION, OPENCAH } from "./Constants/constants"

export default function Error() {
  const styles = {
    textDecoration: "none",
    padding: "13px 20px",
    backgroundColor: "#262c43",
    color: "#ffffff",
    fontSize: "16px"
  }
  const container = {
    padding: "30px"
  }
  const idToken = localStorage.getItem("msal.idtoken");
  return (
    <div style={container}>
      <p>{APPLICATIONPERMISSION}</p><br /><br />
      <a style={styles} href={appConfig.api.umDashboardNavigation + "?umtoken=" + idToken}>{OPENCAH}</a>
    </div>
  )
}
