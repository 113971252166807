import React, { useState, useContext, useEffect } from "react";
import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import TextField from "@mui/material/TextField";
import { TLContext } from "../../Context";
import DeleteTranslation from "./deleteTranslation";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { NewTranslations, GetTranslations } from "../../assets/APIInterface";
import {
  DUPLICATEKEY,
  DUPLICATEMESSAGE,
  GENERICMESSAGE,
  SAVETRANSLATIONMESSAGE,
  ENTER,
  SUCCESS,
  ERROR,
  DEFAULTLANG,
  NONAMEVALUE,
  NONAMEMESSAGE,
  ADDTRANSLATIONPLACEHOLDER,
  WRONGID,
  INFO,
  TRANSLATIONPROGRESS
} from "../Common/Constants/constants";
const AddTranslation = () => {
  const {
    Languages,
    SetAddNewTranslation,
    NewTransKey,
    SetNewTransKey,
    NewTransValue,
    SetNewTransValue,
    SetNotification,
    SetTranslations,
    SelectedApplication,
    SetShowLinearProgress,
    SetTransTotalCount,
    PageNumber,
    SearchQuery,
    RowPerPage,
    setScrollGrid
  } = useContext(TLContext) || {};
  const [errorTransValue, setErrorTransValue] = useState(false);
  const [errorTransKey, setErrorTransKey] = useState(false);
  const [DeleteTransModalOpen, SetDeleteTransModalOpen] = useState(false);
  const [AddingNewTranslation, setAddingNewTranslation] = useState(DEFAULTLANG);

  useEffect(() => {
    SetNewTransKey("");
    SetNewTransValue("");
  }, []);


  const transKeyChange = ({ target: { value } }) => {
    setScrollGrid(false);
    if (value.trim()) {
      setErrorTransKey(false);
      SetNewTransKey(value);
    } else {
      setErrorTransKey(true);
      SetNewTransKey("");
      errorMessageDisplay();
    }
  };

  const transKeyPressed = ({ key, target: { value } }) => {
    setScrollGrid(true);
    if (key === ENTER) {
      if (value.trim()) {
        !NewTransValue.trim() ? setErrorTransValue(true) : newTranslation();
      } else {
        setErrorTransKey(true);
      }
      if (!value || !NewTransValue) {
        errorMessageDisplay();
      }
    }
  };

  const transValuePressed = ({ key, target: { value } }) => {
    setScrollGrid(true);
    if (key === ENTER) {
      if (value.trim()) {
        !NewTransKey.trim() ? setErrorTransKey(true) : newTranslation();
      } else {
        setErrorTransValue(true);
      }
      if (!value || !NewTransKey) {
        errorMessageDisplay();
      }
    }
  };

  const transValueChange = ({ target: { value } }) => {
    setScrollGrid(false);
    if (value.trim()) {
      setErrorTransValue(false);
      SetNewTransValue(value);
    } else {
      setErrorTransValue(true);
      SetNewTransValue("");
      errorMessageDisplay();
    }
  };

  const deleteNewTransRow = () => {
    setScrollGrid(false);
    if (NewTransKey && NewTransValue) {
      SetDeleteTransModalOpen(true);
    } else {
      SetAddNewTranslation(false);
    }
  };

  const newTranslation = () => {
    if (NewTransKey.trim() && NewTransValue.trim()) {
      SetShowLinearProgress(true);
      SetNotification({
        visible: true,
        statusType: INFO,
        notificationText: TRANSLATIONPROGRESS,
        isAutohide: true,
        showProgress: false,
      });

      setScrollGrid(false);
      let Trans = {
        Translations: {
          Key: NewTransKey.trim(),
          Translations: [
            {
              LanguageID: AddingNewTranslation.id,
              ProjectId: SelectedApplication,
              Value: NewTransValue.trim(),
            },
          ],
        },
      };
      NewTranslations(Trans, true, false, (trans) => {
        if (trans.status === SUCCESS) {
          SetNotification({
            visible: true,
            statusType: SUCCESS,
            notificationText: NewTransKey + SAVETRANSLATIONMESSAGE,
            isAutohide: true,
            showProgress: false,
          });
          reloadGrid();
          SetShowLinearProgress(false);
        } else {
          let notificationText = "";
          switch (trans.result) {
            case DUPLICATEKEY: notificationText = DUPLICATEMESSAGE;
              break;

            case NONAMEVALUE || WRONGID: notificationText = NONAMEMESSAGE;
              break;

            default: notificationText = GENERICMESSAGE;
          }
          SetNotification({
            visible: true,
            statusType: ERROR,
            notificationText: notificationText,
            isAutohide: true,
            showProgress: false,
          })
          SetShowLinearProgress(false);
        }
        SetAddNewTranslation(false);
      });
    } else {
      setScrollGrid(true);
      !NewTransKey.trim() ? setErrorTransKey(true) : setErrorTransKey(false);
      !NewTransValue.trim() ? setErrorTransValue(true) : setErrorTransValue(false);
      SetNotification({
        visible: true,
        statusType: ERROR,
        notificationText: NONAMEMESSAGE,
        isAutohide: true,
        showProgress: false,
      });
    }
  };
  const newTransLangHandler = (lang) => setAddingNewTranslation(lang)

  const reloadGrid = () => {
    GetTranslations(SelectedApplication,
      PageNumber, SearchQuery, RowPerPage,
      (QueriedTranslations) => {
        if (QueriedTranslations.status === "success") {
          SetTranslations(QueriedTranslations.result)
          SetTransTotalCount(QueriedTranslations.totalCount);
        }
      });
  }

  const errorMessageDisplay = () => {
    SetNotification({
      visible: true,
      statusType: ERROR,
      notificationText: NONAMEMESSAGE,
      isAutohide: true,
      showProgress: false,
    })
  }

  const createTableRow = () => {
    const cell = [];
    cell.push(
      <TableCell className="addTransCell">
        <div className="addCell">
          <ClearIcon
            fontSize="small"
            className="clearIcon"
            onClick={() => deleteNewTransRow()}
          />
          <CheckIcon
            fontSize="small"
            className="checkIcon"
            onClick={() => newTranslation()}
          />
        </div>
      </TableCell>
    );
    cell.push(
      <TableCell className="addTransCell">
        <TextField
          id="tblrowtransKey"
          className="addTrans"
          variant="outlined"
          size="small"
          placeholder={ADDTRANSLATIONPLACEHOLDER}
          required
          error={errorTransKey}
          onKeyPress={transKeyPressed}
          onChange={transKeyChange}
        />
      </TableCell>
    );
    Languages.map((header) => {
      if (header.Name === AddingNewTranslation.Name) {
        cell.push(
          <TableCell className="addTransCell">
            <TextField
              id="tblrowtransValue"
              className="addTrans"
              variant="outlined"
              size="small"
              placeholder={ADDTRANSLATIONPLACEHOLDER}
              error={errorTransValue}
              onKeyPress={transValuePressed}
              onChange={transValueChange}
            />
          </TableCell>
        );
      } else {
        cell.push(
          <TableCell onClick={() => newTransLangHandler(header)} className="addTransCell cursorPointer">
            <div className="transText" data-ph="Add translation"></div>
          </TableCell>
        );
      }
    });
    return cell;
  };

  function onDeleteTransModalClose() {
    SetDeleteTransModalOpen(false);
  }
  return (
    <>
      <TableRow>{createTableRow()}</TableRow>
      <DeleteTranslation
        onDeleteTransModalClose={onDeleteTransModalClose}
        Trans={NewTransKey}
        DeleteTransModalOpen={DeleteTransModalOpen}
      />
    </>
  );
};

export default AddTranslation;