import { makeStyles } from "@mui/styles";

const searchStyles = makeStyles((theme) => ({
  SearchText: {
    width: "100%",
    margin: "0px 40px 0px 40px !important",
    minWidth: "790px",
    lineHeight: "28px !important",
    "& .MuiIconButton-root": {
      padding: "0px 4px 0px 0px !important"
    },

    "& .MuiInput-input": {
      padding: "0px",
      height: "40px !important",
      fontSize: "24px !important",
      fontFamily: "Roboto  !important",
      fontStyle: "normal",
      fontWeight: "normal",
      lineHeight: "28px !important",
      color: "#617080 !important",
      "&::placeholder": {
        color : "#617080 !important",
        opacity : "1"
      }
    },
  },
  InputAdorment: {
    padding: "0px",
    marginRight: "0px !important"
  },
  SearchIconStyles:{
    color: "#617080 !important"
  }
}));
export default searchStyles;
