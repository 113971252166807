import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import TranslationGrid from "./translationGrid/translationGrid";
import {
  GetLanguages,
  GetTranslations,
  GetProjects
} from "../assets/APIInterface";
import Notifications from "./Common/Notifications/notification";
import Header from "../components/header/Header";
import ApplicationDropdown from "../components/filter/applicationDropdown/Application";
import LanguageDropdown from "../components/filter/languageDropdown/Language";
import SearchInput from "../components/filter/search/Search";
import {
  ADDTRANSLATION,
  SUCCESS,
  SVGCLICK,
  SVGPATH,
  DEFAULTROWNUMBER,
  IMPORTEXPORT
} from "./Common/Constants/constants";
import { TLContext } from "./../Context";
import { Backdrop, Button } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BulkUpload from "./filter/bulkUpload/BulkUpload";
import { HasPermission } from "../utilities/applicationValidateutils";
import Error from "./Common/Error";
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    background: "#f0f0f4 !important",
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 24px 0px",
    height: "40px",
    marginTop: "32px !important"
  },
  addTransBtnContainer:{
    display:'flex',
    justifyContent: "center",
    alignItems: "center",

  },
  buttonBackground:{
    background:'#ffffff !important'
  },
  addtranslation: {
    margin:'0 15px !important',
    width: "165px",
    padding: "0px !important",
    background: "linear-gradient(99.7deg, #15205E -19.43%, #0076F4 80.93%)",
    boxShadow: "0px 11px 12px -10px rgb(4 105 221 / 70%)",
    flex: "none",
    flexGrow: "0",
    color: "#fff",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    float: "left",
    height: "40px",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    fontFamily: "Roboto",
  },
  backdropStyle: {
    zIndex: '10000',
    color:'white'
  }
}));
const Main = (props) => {
  const classes = useStyles();
  const [Applications, SetApplications] = useState([]);
  const [Languages, SetLanguages] = useState([]);
  const [DefaultLanguages, SetDefaultLanguages] = useState([]);
  const [Translations, SetTranslations] = useState([]);
  const [NewTransKey, SetNewTransKey] = useState("");
  const [NewTransValue, SetNewTransValue] = useState("");
  const [AddNewTranslation, SetAddNewTranslation] = useState(false);
  const [SelectedLanguages, SetSelectedLanguages] = useState([]);
  const [TransTotalCount, SetTransTotalCount] = useState(0);
  const [SelectedApplication, SetSelectedApplication] = useState(1);
  const [SearchQuery, SetSearchQuery] = useState("");
  const [RowPerPage, SetRowPerPage] = useState(DEFAULTROWNUMBER);
  const [PageNumber, SetPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showLinearProgress, SetShowLinearProgress] = useState(false);
  const [Notification, SetNotification] = useState({
    visible: false,
    statusType: "",
    notificationText: "",
    isAutohide: true,
    showProgress: false
  });
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [BulkMenu, SetBulkMenu] = useState({
    visible: false,
    id: "",
    anchorEl: null
  });
  const [showGrid, setShowGrid] = useState(null);
  const [scrollGrid, setScrollGrid] = useState(false);
  const readPermission = HasPermission(
    "Application Permissions/Read",
    props.props.permissions
  );
  const writePermission = HasPermission(
    "Application Permissions/Write",
    props.props.permissions
  );

  useEffect(() => {
    setIsLoading(true);
    getProjects();
  }, []);

  const getProjects = () => {
    GetProjects((QueriedProjects) => {
      if (QueriedProjects.status === SUCCESS) {
        SetApplications(QueriedProjects.result);
        getLanguages();
      } else {
        setShowGrid(true);
        setIsLoading(false);
      }
    });
  };

  const getLanguages = () => {
    const langs = [];
    GetLanguages((QueriedLanguages) => {
      if (QueriedLanguages.status === SUCCESS) {
        setShowGrid(false);
        SetLanguages(QueriedLanguages.result);
        SetDefaultLanguages(QueriedLanguages.result);
        QueriedLanguages.result.forEach((lang) => {
          langs.push(lang["Name"]);
        });
        SetSelectedLanguages(langs);
        getTranslations();
      } else {
        setShowGrid(true);
      }
    });
  };

  const getTranslations = () => {
    GetTranslations(
      SelectedApplication,
      PageNumber,
      SearchQuery,
      RowPerPage,
      (QueriedTranslations) => {
        if (QueriedTranslations.status === SUCCESS) {
          SetTranslations(QueriedTranslations.result);
          SetTransTotalCount(QueriedTranslations.totalCount);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    );
  };

  const addTranslation = ({
    target: { localName,name },
    currentTarget,
    currentTarget: { id }
  }) => {
    if (localName != SVGCLICK && localName != SVGPATH && name !== 'import-export-button' ) {
      SetAddNewTranslation(true);
    } else {
      SetBulkMenu({
        visible: true,
        id: id,
        anchorEl: currentTarget
      });
    }
  };

  const handleClose = () => {
    SetBulkMenu(false);
  };

  const provider = {
    Permission: props.props,
    Applications,
    SetApplications,
    Languages,
    Translations,
    SetTranslations,
    SearchQuery,
    SetSearchQuery,
    SelectedApplication,
    SetSelectedApplication,
    RowPerPage,
    SetRowPerPage,
    PageNumber,
    SetPageNumber,
    TransTotalCount,
    SetTransTotalCount,
    isLoading,
    setIsLoading,
    SetSelectedLanguages,
    SelectedLanguages,
    SetLanguages,
    DefaultLanguages,
    AddNewTranslation,
    SetAddNewTranslation,
    Notification,
    SetNotification,
    NewTransKey,
    SetNewTransKey,
    NewTransValue,
    SetNewTransValue,
    showLinearProgress,
    SetShowLinearProgress,
    showGrid,
    BulkMenu,
    SetBulkMenu,
    scrollGrid,
    setScrollGrid,
    readPermission,
    writePermission,
    setShowBackdrop
  };

  return (
    <div className={classes.root}>
      <TLContext.Provider value={provider}>

        <Header />
        {readPermission
          ?
          <>
            <Backdrop className={classes.backdropStyle} open={showBackdrop} >
              <CircularProgress color="inherit" />
            </Backdrop>
            <div className={classes.mainContainer}>
              <ApplicationDropdown />
              <LanguageDropdown />
              <SearchInput />
              {
                writePermission &&
                (<div className={classes.addTransBtnContainer}>
                  <Button
                    id="add-button"
                    variant="contained"
                    className={classes.addtranslation}
                    onClick={addTranslation}
                    aria-haspopup="true"
                    aria-controls="basic-menu"
                    disabled={Applications.length === 0 || Languages.length === 0}                    
                  >
                    {ADDTRANSLATION}
                  </Button>
                  <Button
                    id="import-export-button"
                    variant="outlined"
                    onClick={addTranslation}
                    className={classes.buttonBackground}
                    name='import-export-button'
                    aria-haspopup="true"
                    aria-controls="basic-menu"
                    disabled={Applications.length === 0 || Languages.length === 0}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    {IMPORTEXPORT}
                  </Button>
                  <BulkUpload />
                </div>)
              }
            </div>
            <TranslationGrid />
            <Notifications />
          </>
          :
          <Error />

        }
      </TLContext.Provider>
    </div>
  );
};

export default Main;