import { makeStyles } from "@mui/styles";

const bulkIploadStyles = makeStyles((theme) => ({
  MenuUpload: {
    minHeight: "0px !important",
    "& .MuiPaper-root": {
      top: "131px!important",
    },
    "& .MuiMenu-list": {
      padding: "0px"
    },
    "& .MuiMenuItem-root": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "21px",
      color: " #333333",
      padding:'6px 8px'
    }
  },
  LanguageDropDown: {
    background: "#FFFFFF !important",
    width: "328px!important",
    borderRadius: "4px !important",
    cursor: "pointer !important",
    pointerEvents: "auto",
    marginTop: "16px",
    "& .MuiSelect-select": {
      width: "328px!important",
      padding: " 10.5px  0px 0.5px 14px !important",
      fontStyle: "normal !important",
      fontSize: "14px",
      "& .MuiSvgIcon-root": {
        transform: "translateY(-16px) !important"
      }
    },
    "&  .MuiPaper-root": {
      width: "327px! important"
    }
  },
  LanMenuItem: {
    background: "#FFFFFF !important",
    "&  .MuiPaper-root-MuiPopover-paper": {
      width: "327px! important"
    }
  },
  FlagImage: {
    borderRadius: "50%",
    position: "relative",
    right: "10px"
  },
  LangText: {
    position: "relative",
    bottom: "7px",
    right: "-1px"
  },
  TranslationKeyInput: {
    width: "328px !important",
    height: "40px !important",
    background: "#E5E7ED  !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px !important"
    },
    "& .MuiOutlinedInput-input": {
      position: "static",
      width: " 316px",
      left: "calc(50% - 316px/2)",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#152C73 !important",
      order: "1",
      alignSelf: "stretch",
      flexGrow: "0",
      margin: "8px 0px"
    },
    "& .MuiInputLabel-root": {
      top: "14px !important",
      color: "#617080 !important",
      height: "12px !important",
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "normal !important",
      fontSize: "10px !important",
      lineHeight: "12px"
    }
  },
  DivFileUpload: {
    marginTop: "10px"
  },
  InputWidth: {
    maxWidth: "54%",

  },
  FileDiv: {
    "column-gap": "5px",
    display: "flex",
    "flex-direction": "row",
    marginTop: "16px"
  },
  FileButton: {
    textTransform: "none !important",
    borderColor: "black !important",
    backgroundColor: "#e5e5e5 !important",
    height: "23px !important"
  },
  FileLable: {
    paddingTop: "5px",
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  InputActual: {
    display: "none"
  },
  divider: {
    margin:'0 8px !important'
  }
}));
export default bulkIploadStyles;
