import React, {useContext } from "react";
import CommonDialog from "../Common/Dialog/commonDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { TLContext } from "../../Context";
import {
  DELETEMESSAGE,
  DELETEHEADER,
  DELETEMESSAGESUCCESS,
  SUCCESS,
  ERROR,
  DELETETRANSLATIONPROGRESS,
  INFO
} from "../Common/Constants/constants";
import { DeleteTranslationBasedOnTransKey, GetTranslations } from "../../assets/APIInterface";
const DeleteTranslation = (props) => {
  const {
    AddNewTranslation,
    SetAddNewTranslation,
    SetNotification,
    SetNewTransKey,
    SetNewTransValue,
    SetShowLinearProgress,
    SelectedApplication,
    Translations,
    SetTranslations,
    SetTransTotalCount,
    PageNumber,
    SearchQuery,
    RowPerPage,
    setShowBackdrop
  } = useContext(TLContext) || {};

  const { TransIndex ,Trans, DeleteTransModalOpen, onDeleteTransModalClose} = props;

  const createActionsButton = () => {
    return [
      {
        id: "Yes_Deletetag",
        name: "Delete",
        onClick: onDelete,
      },
    ];
  };

  const onDelete = () => {
    if ((TransIndex === undefined || "") && AddNewTranslation) {
      SetAddNewTranslation(false);
      SetNewTransKey("");
      SetNewTransValue("");
    } else {
      setShowBackdrop(true)
      SetShowLinearProgress(true);
      SetNotification({
        visible: true,
        statusType: INFO,
        notificationText:DELETETRANSLATIONPROGRESS,
        isAutohide: true,
        showProgress: true,
      });
      onDeleteTransModalClose();
      let DelTrans = {
        Translations: {
          TransKey: Trans,
          ProjectId: SelectedApplication
        },
      };
      DeleteTranslationBasedOnTransKey(
        DelTrans,
        (trans) => {
          SetAddNewTranslation(false);
          if (trans.status === SUCCESS) {
            reloadGrid();
            SetNotification({
              visible: true,
              statusType: SUCCESS,
              notificationText: `${Trans} ${DELETEMESSAGESUCCESS}`,
              isAutohide: true,
              showProgress: false,
            });
            SetShowLinearProgress(false);
          } else {
              SetNotification({
                visible: true,
                statusType: ERROR,
                notificationText: GENERICMESSAGE,
                isAutohide: true,
                showProgress: false,
              });            
            SetShowLinearProgress(false);
            setShowBackdrop(false)
          }
        }
      );
    }
  };

  const reloadGrid = () => {
    GetTranslations(SelectedApplication,
      PageNumber,SearchQuery,RowPerPage,
    (QueriedTranslations) => {
      if(QueriedTranslations.status === "success"){
        SetTranslations(QueriedTranslations.result)
        SetTransTotalCount(QueriedTranslations.totalCount);
        setShowBackdrop(false)
      }
    });
  }

  const DeleteTranslationData = () => {
    return (
      <div>
        <div className="confirmContent">
          <span>{DELETEMESSAGE}</span>
        </div>
        <div className="confirmContentCenterAlign">
          <span>
            <b>
              {Trans} {Trans ? "?" : ""}
            </b>
          </span>
        </div>
      </div>
    );
  };
  return (
    <CommonDialog
      open={DeleteTransModalOpen}
      translationData={DeleteTranslationData()}
      onEditTransModalClose={onDeleteTransModalClose}
      actionButtonList={createActionsButton()}
      title={DELETEHEADER}
      headerIcon={DeleteIcon}
    />
  );
};
export default DeleteTranslation;
