export const validateFormFields = (value, validations, validationMessages) => {
  if (!value) {
    return validationMessages.nameRequired;
  } else if (value.trim() === "") {
    return validationMessages ? validationMessages.noBlankSpacesOnly : "";
  } else if (validations && !value.match(validations.regExp)) {
    return validationMessages ? validationMessages.notAllowedCharacters : "";
  } else if (validations && value.length > validations.maxLength) {
    return validationMessages ? validationMessages.maxLengthError : "";
  } else {
    return "";
  }
};

export const defaultValidation = {
  regExp: /^[^'\"&\/#,<>|\\\\]*$/,
  maxLength: 100
};

export const defaultValidationMessages = {
  nameRequired: "Please fill the required field.",
  notAllowedCharacters: "Special characters are not allowed.",
  maxLengthError: "Number of characters should not be more than 100",
  noBlankSpacesOnly: "Input should not contain blank spaces only"
};

export const transUpdateValidationMessages = {
  nameRequired: "Please edit the required field.",
};

export const HasPermission = (PermissionPath, Permissions) => {
  let FoundTargetPermission = false
  if (PermissionPath != null && Permissions != null) {
      let PermissionPathArray = PermissionPath.split('/')
      let ItemPermission = Permissions
      PermissionPathArray.forEach(treeItem => {
          let ChildSetting = ItemPermission.find(perm => perm.settingName === treeItem.trim())
          if (ChildSetting != null) {
              ItemPermission = ChildSetting.childSettings
              FoundTargetPermission = true
          } else
              FoundTargetPermission = false
      })
  }
  return FoundTargetPermission
}