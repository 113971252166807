
import { makeStyles } from "@mui/styles";

const dataGridStyles = makeStyles((theme) => ({
  genericMsg : {
    textAlign: "center",
    marginTop: "65px"
  },
  gridContainer : {
    marginTop:"72px", 
    padding: "0px 20px 0px",
    '& th.MuiTableCell-root.MuiTableCell-head:nth-child(1)' : {   
      left: "0",
      zIndex: '999',
      width: "20px",
      minWidth :'20px',
      maxWidth : "20px",
      border:"0px !important"
    },
    '& td.MuiTableCell-root.MuiTableCell-body:nth-child(1)' : {
      left: "0",
      position: "sticky",
      top: "0",
      width : "20px",
      minWidth : "20px",
      maxWidth : "20px",
      boxShadow : "none",
      zIndex:"9",
      border: "0px"
    },
    '& th.MuiTableCell-root.MuiTableCell-head:nth-child(2) ' : {
      left: "52px",
      background :'#f0f0f4',
      zIndex: '999'
    },
    ' & td.MuiTableCell-root.MuiTableCell-body:nth-child(2)' : {
      left: "52px",
      position: "sticky",
      top: "0",
      background :'#f0f0f4',
      zIndex:"9"
    },
    '& .MuiTableCell-head,.MuiTableCell-body' : {
      width :"352px",
      minWidth: "352px",
      maxWidth: "352px",
      padding :  "12px 16px 12px 16px"
    },
    '& .MuiPaper-elevation1' :{
      background: "#f0f0f4 !important",
      boxShadow: "none"
    },
    '& .MuiTableCell-root' :{
      textAlign: "left !important",
      background: "#f0f0f4 !important",
      borderBottom : "0px"
    },
    '& .MuiTableCell-head' :{
      fontSize : "14px",
      fontWeight : "700",
      color:"#152C73",
      borderBottom:"1px solid #BAC0D0",
    },
    '& .MuiTableCell-body' :{
      fontSize: "12px",
      color: "#333333",
      fontWeight: "400",
      verticalAlign : "top",
      display: "revert",
      boxShadow : "0 1px 0 0 #BAC0D0, 0 -1px 0 0 #BAC0D0",
      overflowWrap: "break-word",
      '& .transText::before' : {
        content : "attr(data-ph)",
        color: "#BAC0D0 !important"
      }
    },
    '& .langImg' :{
      width :'16px',
      marginRight :'12px',
      marginBottom:'-2px',
      borderRadius:'61px'
    },
    '& .rowEdit' : {
      cursor:"pointer"
    },
    '& .disableCursor' : {
      cursor:"default"
    },
    '& .deleteImg' :{
      cursor:'pointer',
      width :'14.93px',
      height :'18px',
    },
    '& .MuiTextField-root' : {
      width :"100% !Important",
      background :"#fff"
    },
    '& .MuiTableCell-body:not(:first-child):hover' : {
      background: '#E5E7ED !important'
    },
    '& .show' :{
      opacity: 1
    },
    '& .hidden' :{
      opacity: 0,
      visibility: "hidden"
    },
    "& .MuiTablePagination-spacer": {
      display: "none",
    }, 
    '& .circularProgress' : {
      display: "inherit",
      textAlign: "center"
    },
    '& .messageContainer': {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "150px",
      width: "inherit",
    },
    '& .addCell' : {
      display : "flex"
    },
    '& .checkIcon' : {
      cursor: "pointer",
      color: "#FFFFFF",
      borderRadius: "50%",
      background: "#1891F6",
      position:"relative",
      right: "5px"
    },
    "& .clearIcon" : {
      right: "10px",
      position: "relative",
      cursor: "pointer",
      color: "#FFFFFF",
      borderRadius: "50%",
      background: "#333333"
    },
    "& .autotranslate":{
      color: "#617080",
      display:"flex",
      alignItems:"flex-start"
    },
    "& .autotranslateImg" : {
      margin :"0px 8px 0px 0px",
    },
    "& .addTransCell":{
      padding :  "5px 16px 5px 16px",
      verticalAlign: "middle"
    },
    "& .transValue" : {
      width: "98%",
      whiteSpace: "breakSpaces"
    },
    "& .cursorPointer":{
      cursor:'pointer'
    }
  }
}));
export default dataGridStyles;