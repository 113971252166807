import React, { useContext, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import useApplicationStyles from "./ApplicationStyles";
import { GetTranslations } from "../../../assets/APIInterface";
import { TLContext } from "../../../Context";
import AddIcon from "@mui/icons-material/Add";
import {
  DEFAULTPAGENUMBER,
  DEFAULTSEARCHQUERY,
  ADDNEWAPPLICATION,
  SVGPATH,
  SPAN,
  SUCCESS
} from "../../Common/Constants/constants";
import AddNewApplication from "./AddNewApplication";
import EditApplication from "./EditApplication";
import { getImageDetails } from "../../../utilities/imagesutils";
const Application = (props) => {
  const {
    ApplicationDropDown,
    Paper,
    Icon,
    Popper,
    SearchTextInput,
    divEdit,
    spanContent,
    spanContentAddApp,
    SpanArrowDown
  } = useApplicationStyles();
  const {
    Applications,
    SetTranslations,
    SelectedApplication,
    SetSelectedApplication,
    RowPerPage,
    SetSearchQuery,
    SetTransTotalCount,
    setIsLoading,
    SetPageNumber,
    SetAddNewTranslation,
    writePermission
  } = useContext(TLContext) || {};
  const [AddNewApplicationOpen, SetAddNewApplicationOpen] = useState(false);
  const [showActionId, setShowActionId] = useState(-1);
  const [AppName, setAppName] = useState("");
  const [APPIndex, setAppIndex] = useState();
  const [APPHighlighted, setAppHighlighted] = useState(null);
  const [EditAppModalOpen, SetEditAppModalOpen] = useState(false);
  const [OptionList, SetOptionList] = useState([]);
  const [SelectedAppIndex, setSelectedAppIndex] = useState("");
  const [open, setOpen] = useState(false);

  const closePopper = () => {
    setOpen(false);
    SetOptionList([{ id: 0, Name: ADDNEWAPPLICATION }, ...Applications]);
  };
  const openPopper = () => setOpen(true);

  useEffect(() => {
    SetOptionList([{ id: 0, Name: ADDNEWAPPLICATION }, ...Applications]);

    setAppHighlighted(APPHighlighted ? APPHighlighted : Applications.find((obj) => obj.id === 1));
  }, [Applications]);

  const handleApplicationSelect = ({ target: { textContent, localName } },newValue) => {
    if (newValue) {
      if (newValue.id !== 0 && textContent !== "" && localName !== SVGPATH) {
        setAppIndex(newValue.id);
        setAppHighlighted(newValue);
        SetSelectedApplication(newValue.id);
        SetPageNumber(DEFAULTPAGENUMBER);
        SetSearchQuery("");
        laodTranslationGrid(newValue.id);
      } else {
        if (
          (newValue.id === 0 && localName === SVGPATH) ||
          (newValue.id === 0 && textContent !== "" && localName === SPAN)
        ) {
          SetAddNewApplicationOpen(true);
        }
      }
    }
  };

  const handleUpdate = (newtext) => {
    SetAddNewApplicationOpen(newtext);
  };

  const laodTranslationGrid = (projectSelected) => {
    setIsLoading(true);
    SetTranslations([]);
    SetAddNewTranslation(false);
    GetTranslations(
      projectSelected,
      DEFAULTPAGENUMBER,
      DEFAULTSEARCHQUERY,
      RowPerPage,
      (QueriedTranslations) => {
        if (QueriedTranslations.status === SUCCESS) {
          SetTranslations(QueriedTranslations.result);
          SetTransTotalCount(QueriedTranslations.totalCount);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    );
  };

  const editAppsRow = (option) => {
    setSelectedAppIndex(
      OptionList.findIndex((obj) => obj.Name === option.Name)
    );
    setAppIndex(option.id);
    SetEditAppModalOpen(true);
    setAppName(option.Name);
  };

  const onEditAppModalClose = (isModalClosed,updatedApp) => {
    SetEditAppModalOpen(false);
      setAppName(null);
      if(updatedApp.id===APPHighlighted.id)
      {
        setAppHighlighted(updatedApp)
      }
    
  };

  const renderUserSelectApplication = (params) => {
    if (OptionList.length !== 0) {
      const selectedApp = OptionList.find(
        (obj) => obj.id === SelectedApplication
      );
      return (
        <TextField
          {...params}
          label={selectedApp ? selectedApp.Name : ""}
          InputLabelProps={{ shrink: false }}
          onClick={openPopper}
        />
      );
    }
  };

  const OnTextChangeFilter = ({ target: { value } }) => {
    if (value != "") {
      const filteredOtionList = Applications.filter((app) =>
        app.Name.toLowerCase().includes(value.toLowerCase())
      );
      filteredOtionList.splice(0, 0, { id: 0, Name: ADDNEWAPPLICATION });

      SetOptionList(filteredOtionList);
    } else {
      SetOptionList([{ id: 0, Name: ADDNEWAPPLICATION }, ...Applications]);
    }
  };

  const optionLabel = (option) => {
    if (option.id === 0) {
      return (
        <div>
          <div>
            <span>
              <TextField
                className={SearchTextInput}
                variant="standard"
                onChange={OnTextChangeFilter}
                autoFocus
                InputProps={{
                  disableUnderline: true
                }}
              />
            </span>
            <span className={SpanArrowDown}>
              <img src={getImageDetails("ArrowDown").img}></img>
            </span>
          </div>
          {writePermission &&
            <div>
              <span>
                <AddIcon className={Icon} />
                <span className={spanContentAddApp}>{option.Name}</span>
              </span>
            </div>
          }          
        </div>
      );
    } else {
      return (
        <div
          className={divEdit}
          onMouseLeave={() => {
            setShowActionId(-1);
          }}
        >
          <span>
            <img
              className={
                "EditIcon " + (option.id === showActionId && writePermission ? "show" : "hidden")
              }
              src={getImageDetails("Edit").img}
              alt=""
              onClick={(e) => {
                writePermission && editAppsRow(option);
              }}
            />
          </span>
          <span
            className={spanContent}
            onMouseEnter={() => {
              setShowActionId(option.id);
            }}
          >
            {option.Name}
          </span>
        </div>
      );
    }
  };
  return (
    OptionList && (
      <div onBlur={closePopper}>
        <Autocomplete
          disablePortal
          open={open}
          className={ApplicationDropDown}
          classes={{ paper: Paper, popper: Popper }}
          options={OptionList}
          getOptionLabel={optionLabel}
          id="select-app"
          renderInput={(params) => renderUserSelectApplication(params)}
          onChange={handleApplicationSelect}
          onOpen={openPopper}
          onClose={closePopper}
          disableClearable
          value={APPHighlighted}
        ></Autocomplete>
        <AddNewApplication
          Update={handleUpdate}
          AddNewApplicationOpen={AddNewApplicationOpen}
        />
        <EditApplication
          onEditAppModalClose={onEditAppModalClose}
          EditAppModalOpen={EditAppModalOpen}
          AppName={AppName}
          APPIndex={APPIndex}
          SelectedAppIndex={SelectedAppIndex}
        />
      </div>
    )
  );
};

export default Application;
