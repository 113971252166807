let postLogoutRedirectUri = 'https://qatranslation.carrier.com'
let tenant = "carriertools.onmicrosoft.com";

export const appConfig = {
  api: {
    apiUrl: "https://translationservice-qa.carrier.com/api/v1/",
    umDashboardNavigation: "https://www.carrierappshub.com/",
  },
  loginConfig: {
    apiUrl: 'https://api.carrierappshub1.com/',
    uiUrl: postLogoutRedirectUri
  }
};
export default appConfig;
