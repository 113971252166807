import React, { useContext, useState, useRef } from "react";
import { read, utils } from 'xlsx';
import * as XLSX from 'xlsx';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { TLContext } from "../../../Context";
import bulkIploadStyles from "./BulkUploadStyles";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import CommonDialog from "../../Common/Dialog/commonDialog";
import {
  UPLOADBULKHEADER,
  UPLOADBULKNOFILEVALIDATION,
  IMPORTMENU,
  EXPORTALL,
  EXPORTAUTOTRANSLATION,
  LANGUAGES,
  ERROR,
  SUCCESS,
  INFO,
  BULKIMPORTPSUCCESS,
  BULKIMPORTPROGRESS,
  GENERICMESSAGE,
  FileUploadValidation,
  UPLOADBULKFILEVALIDATION,
  EXPORTSUCCESS,
  NOTRANSLATIONSAVAILABLE,
  ADDALLTRANSLATIONURI,
  ADDBULKTRANSLATIONURI
} from "../../Common/Constants/constants";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  AddBulkTranslation,
  GetTranslations,
  GetAllTranslationsByProjectId,
  GetAllAutoTranslationsByProjectId
} from "../../../assets/APIInterface";
import { Divider } from "@mui/material";

const BulkUpload = (props) => {
  const MenuProps = {
    PaperProps: {
      style: {
        width: "327px"
      }
    }
  };
  const {
    BulkMenu,
    SetBulkMenu,
    SetAddNewTranslation,
    Applications,
    SelectedApplication,
    Languages,
    PageNumber,
    SearchQuery,
    RowPerPage,
    SetNotification,
    SetShowLinearProgress,
    SetTranslations,
    SetTransTotalCount,
    setShowBackdrop
  } = useContext(TLContext) || {};
  const acceptedFormats = ['JSON', 'XLSX', 'CSV', 'XLS']
  const [selected, setSelected] = useState(null);
  const [selectedLangId, setSelectedLangId] = useState("");
  const {
    MenuUpload,
    LanguageDropDown,
    TranslationKeyInput,
    LangText,
    FileButton,
    FileLable,
    InputActual,
    FileDiv,
    divider
  } = bulkIploadStyles();
  const { visible, id, anchorEl } = BulkMenu;
  const [UploadBulkData, setUploadBulkData] = useState(false);
  const [fileError, setfileError] = useState("");
  const [validButton, setValidButton] = useState(false);
  const [fileFormData, setFileFormData] = useState(null);
  const hiddenFileInput = useRef();
  const [projectFileName, setprojectFileName] = useState(null);
  const [translationUri, SetTranslationsUri] = useState(ADDBULKTRANSLATIONURI)

  const createActionsButton = () => {
    return [
      {
        id: "Yes_Validatetag",
        name: "Validate",
        onClick: onValidate,
        disabled: validButton
      }
    ];
  };

  const onValidate = () => {
    if (fileError === "" && fileFormData && selectedLangId) {
      setValidButton(true);
      SetShowLinearProgress(true);
      SetNotification({
        visible: true,
        statusType: { INFO },
        notificationText: BULKIMPORTPROGRESS,
        isAutohide: true,
        showProgress: true
      });

      AddBulkTranslation(
        SelectedApplication,
        selectedLangId,
        fileFormData,
        (QueriedTransBulk) => {
          if (QueriedTransBulk.status === SUCCESS) {
            SetNotification({
              visible: true,
              statusType: SUCCESS,
              notificationText: BULKIMPORTPSUCCESS,
              isAutohide: true,
              showProgress: false
            });
            setFileFormData(null);

            SetShowLinearProgress(false);
            reloadGrid();
            setSelected([]);
            setfileError("");
            onUploadBulknModalClose();
          } else {
            SetNotification({
              visible: true,
              statusType: ERROR,
              notificationText: GENERICMESSAGE,
              isAutohide: true,
              showProgress: false
            });
            setValidButton(false);
            SetShowLinearProgress(false);
            onUploadBulknModalClose();
          }
        },
        translationUri
      );
    } else {
      setfileError(FileUploadValidation);
    }
  };

  const reloadGrid = () => {
    GetTranslations(
      SelectedApplication,
      PageNumber,
      SearchQuery,
      RowPerPage,
      (QueriedTranslations) => {
        if (QueriedTranslations.status === SUCCESS) {
          SetTranslations(QueriedTranslations.result);
          SetTransTotalCount(QueriedTranslations.totalCount);
        }
      }
    );
  };

  const handleClose = () => {
    SetBulkMenu({
      visible: false
    });
  };

  const onMenuchangeBulkUpload = () => {
    SetAddNewTranslation(false);
    setUploadBulkData(true);
    SetBulkMenu({
      visible: false
    });
  };

  const handleExportToCSV = () => {
    setShowBackdrop(true);
    let selectedLangIds = Languages.map(lang => lang.id);
    let formData = {
      projectId: SelectedApplication,
      languageIds: selectedLangIds
    }
    GetAllTranslationsByProjectId(formData, (AllTranslations) => {
      if (AllTranslations.status === SUCCESS) {
        const jsonData = AllTranslations.result;
        if (jsonData.length > 0) {
          const csvData = getConvertedTranslations(jsonData, Languages)
          convertToCSV(csvData);
          SetNotification({
            visible: true,
            statusType: SUCCESS,
            notificationText: EXPORTSUCCESS,
            isAutohide: true,
            showProgress: false
          });
        } else {
          SetNotification({
            visible: true,
            statusType: ERROR,
            notificationText: NOTRANSLATIONSAVAILABLE,
            isAutohide: true,
            showProgress: false
          });
        }
      } else {
        SetNotification({
          visible: true,
          statusType: ERROR,
          notificationText: GENERICMESSAGE,
          isAutohide: true,
          showProgress: false
        });
      }
      setShowBackdrop(false);
    });
  };
  const getConvertedTranslations = (translationData, translationMapping) => {
    let conversionResult = []
    translationData.forEach((translation) => {
      let result = {}
      result["Translation Key"] = translation['TransKey']
      translation["Translations"].forEach(({ LanguageID, Value }) => {
        if (LanguageID) {
          const LanguageInfo = translationMapping.filter(mapping => mapping.id === LanguageID)[0]
          if (LanguageInfo && LanguageInfo.Name) {
            result[`${LanguageInfo.Name}(${LanguageID})`] = Value
          }
        }
      })
      conversionResult.push(result)
    });
    return conversionResult
  }

  function convertToCSV(data) {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Translations');
    XLSX.writeFile(wb, 'Translations.csv');
    SetBulkMenu({
      visible: false
    });
  }

  const handleAutoTranslations = () => {
    setShowBackdrop(true);
    GetAllAutoTranslationsByProjectId(SelectedApplication, (AllAutoTranslations) => {
      if (AllAutoTranslations.status === SUCCESS) {
        const jsonData = AllAutoTranslations.result;
        if (jsonData.length > 0) {
          const csvData = getConvertedTranslations(jsonData, Languages)
          convertToCSV(csvData);
          SetNotification({
            visible: true,
            statusType: SUCCESS,
            notificationText: EXPORTSUCCESS,
            isAutohide: true,
            showProgress: false
          });
        }
        else {
          SetNotification({
            visible: true,
            statusType: ERROR,
            notificationText: NOTRANSLATIONSAVAILABLE,
            isAutohide: true,
            showProgress: false
          });
        }
      } else {
        SetNotification({
          visible: true,
          statusType: ERROR,
          notificationText: GENERICMESSAGE,
          isAutohide: true,
          showProgress: false
        });
      }
      setShowBackdrop(false);
    });
  };

  const handleChange = ({ target: { value } }) => {
    setSelected(value);
    setSelectedLangId(value.id);
    if (value && fileFormData) {
      setfileError("");
    }
  };

  const onUploadBulknModalClose = () => {
    setValidButton(false);
    setSelected(null);
    setSelectedLangId("");
    setfileError("");
    setUploadBulkData(false);
    setFileFormData(null);
    setprojectFileName(null);
    SetBulkMenu({
      visible: false
    });
  };

  const getProjectFileExtension = (filename) => {
    return filename.split(".").pop().toUpperCase();
  };

  const onChangeHandler = ({ target: { files, name } }) => {
    if (files.length > 0) {
      setprojectFileName(files[0].name)
      const fileExtension = getProjectFileExtension(files[0].name);
      if (!acceptedFormats.includes(fileExtension)) {
        setfileError(UPLOADBULKFILEVALIDATION);
      } else {
        let formData = new FormData();
        formData.append(name, files[0]);
        setFileFormData(formData);
        if (fileExtension !== 'JSON') {
          SetTranslationsUri(ADDALLTRANSLATIONURI)
        } else {
          SetTranslationsUri(ADDBULKTRANSLATIONURI)
        }
        if ((formData && selectedLangId) || fileError === UPLOADBULKFILEVALIDATION) {
          setfileError("");
        }
      }
    } else {
      setfileError(UPLOADBULKNOFILEVALIDATION);
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  }

  const bulkuploadControls = () => {
    return (
      Applications &&
      SelectedApplication && (
        <div>
          <div>
            <TextField
              className={TranslationKeyInput}
              id="outlined-basic"
              label="Application name"
              size="small"
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              value={
                Applications.find((obj) => obj.id === SelectedApplication)
                  ? Applications.find((obj) => obj.id === SelectedApplication)
                    .Name
                  : ""
              }
            />
          </div>
          <div>
            <Select
              labelId="demo-simple-select-label"
              className={LanguageDropDown}
              id="demo-simple-select"
              MenuProps={MenuProps}
              displayEmpty
              value={selected}
              renderValue={(selected) => {
                return (
                  <div>
                    <span className={LangText}>
                      {selected ? selected.Name : LANGUAGES}
                    </span>
                  </div>
                );
              }}
              onChange={handleChange}
            >
              {Languages.map((option) => (
                <MenuItem key={option} value={option}>
                  <ListItemText
                    primary={
                      option.Name.charAt(0).toUpperCase() +
                      option.Name.slice(1).toLowerCase()
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={FileDiv}>
            <Button
              id="ChooseFileImportProject"
              size="small"
              className={FileButton}
              onClick={handleClick}
              variant="outlined"
              disableRipple
            >
              {"ChooseFile"}
            </Button>
            <label title={projectFileName}
              className={FileLable}
            >
              {projectFileName || "NoFileChosen"}
            </label>
          </div>
          <input
            id="importProjectName"
            type="file"
            accept="application/JSON,.csv,.xlsx"
            ref={hiddenFileInput}
            onChange={onChangeHandler}
            name="translationJSONFile"
            className={InputActual}
          />
        </div>
      )
    );
  };

  return (
    <div>
      <Menu
        className={MenuUpload}
        id="basic-menu"
        anchorEl={anchorEl}
        open={visible}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": id
        }}
      >
        <MenuItem id='import-translation' onClick={onMenuchangeBulkUpload}>{IMPORTMENU}</MenuItem>
        <Divider className={divider} variant="middle" />
        <MenuItem id="export-all" onClick={handleExportToCSV} >{EXPORTALL}</MenuItem>
        <MenuItem id="export-auto-translation" onClick={handleAutoTranslations}>{EXPORTAUTOTRANSLATION}</MenuItem>
      </Menu>
      <CommonDialog
        open={UploadBulkData}
        translationData={bulkuploadControls()}
        onEditTransModalClose={onUploadBulknModalClose}
        actionButtonList={createActionsButton()}
        title={UPLOADBULKHEADER}
        headerIcon={UploadFileIcon}
        errorMsg={fileError}
      />
    </div>
  );
};
export default BulkUpload;
