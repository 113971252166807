import React, { useState } from "react";
import Main from "./components/Main";
import appConfig from "./Environment/environments";
import { ReactAuthWrapper } from "@carrier/reactauthwrapper";
import "@fontsource/roboto";

const App = () => {
  const [permission, SetPermission] = useState(null);

  const authCallback = (data) => {
    SetPermission(data);
  };

  return (
    <ReactAuthWrapper
      appConfig={appConfig.loginConfig}
      getPermissions={authCallback}
    >
      <Main props={permission} />
    </ReactAuthWrapper>
  );
};
export default App;
