import { makeStyles } from "@mui/styles";

const editTranslationComponentsStyles = makeStyles((theme) => ({
  TranslationKeyInput: {
    width: "328px !important",
    background: "#E5E7ED  !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px !important"
    },
    "& .MuiOutlinedInput-input": {
      position: "static",
      width: " 316px",
      left: "calc(50% - 316px/2)",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#152C73 !important",
      order: "1",
      alignSelf: "stretch",
      flexGrow: "0",
      margin: "12px 0px"
    },
    "& .MuiInputLabel-root": {
      top: "14px !important",
      color: "#617080 !important",
      height: "12px !important",
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "normal !important",
      fontSize: "10px !important",
      lineHeight: "12px"
    }
  },
  EnglishInput: {
    width: "328px !important",
    background: "#F3FAFF !important",
    marginTop: "8px !important",
    overflowY: "hidden !important",
    "& .MuiTextField-root": {
      marginTop: "8px !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px !important"
    },
    "& .MuiOutlinedInput-input": {
      position: "static",
      width: " 316px",
      left: "calc(50% - 316px/2)",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#152C73 !important",
      order: "1",
      alignSelf: "stretch",
      flexGrow: "0",
      margin: "12px 0px"
    },
    "& .MuiInputLabel-root": {
      top: "14px !important",
      color: "#617080 !important",
      height: "12px !important",
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "normal !important",
      fontSize: "10px !important"
    }
  },
  LangInputExisting: {
    width: "328px !important",
    background: "#FFFFFF !important",
    marginTop: "8px !important",
    overflowY: "hidden !important",
    "& .MuiTextField-root": {
      marginTop: "8px !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px !important"
    },
    "& .MuiOutlinedInput-input": {
      position: "static",
      width: " 316px",
      left: "calc(50% - 316px/2)",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#152C73 !important",
      order: "1",
      alignSelf: "stretch",
      flexGrow: "0",
      margin: "12px 0px"
    },
    "& .MuiInputLabel-root": {
      top: "14px !important",
      color: "#617080 !important",
      height: "12px !important",
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "normal !important",
      fontSize: "10px !important"
    }
  },
  LangInputNew: {
    width: "328px !important",
    background: "#F3FAFF !important",
    marginTop: "8px !important",
    overflowY: "hidden !important",
    "& .MuiTextField-root": {
      marginTop: "8px !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px !important"
    },
    "& .MuiOutlinedInput-input": {
      position: "static",
      width: " 316px",
      left: "calc(50% - 316px/2)",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#152C73 !important",
      order: "1",
      alignSelf: "stretch",
      flexGrow: "0",
      margin: "12px 0px"
    },
    "& .MuiInputLabel-root": {
      top: "14px !important",
      color: "#617080 !important",
      height: "12px !important",
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "normal !important",
      fontSize: "10px !important"
    },
    "& .MuiOutlinedInput-root": {}
  },
  LangInputNewError: {
    width: "328px !important",
    background: "#F3FAFF !important",
    marginTop: "8px !important",
    overflowY: "hidden !important",
    "& .MuiTextField-root": {
      marginTop: "8px !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px !important"
    },
    "& .MuiOutlinedInput-input": {
      position: "static",
      width: " 316px",
      left: "calc(50% - 316px/2)",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#152C73 !important",
      order: "1",
      alignSelf: "stretch",
      flexGrow: "0",
      margin: "12px 0px"
    },
    "& .MuiInputLabel-root": {
      top: "14px !important",
      color: "#617080 !important",
      height: "12px !important",
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "normal !important",
      fontSize: "10px !important"
    },
    "& .MuiOutlinedInput-root": {}
  },
  AutoTransLateCheckBox: {
    top: "0px !important",
    padding: "0px  !important",
    marginRight: "2px !important"
  },
  AutoTransLateTextSpan: {
    "& .MuiTypography-root": {
      fontWeight: "bold !important",
      fontSize: "14px !important"
    }
  },
  AutoTranslateDiv: {
    marginTop: "8px !important"
  }
}));

export default editTranslationComponentsStyles;
