import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import useEditTranslationStyles from "./editTranslationStyles";
import CommonDialog from "./../Common/Dialog/commonDialog";
import {
  EDITTRANSLATIONHEADER,
  ERROR,
  UPDATETRANSLATIONMESSAGE,
  SUCCESS,
  LANGENGLISH,
  ADDAUTOTRANSLATION,
  CURRENT,
  TRANSLATION,
  NEW,
  VALIDATEMESSAGE,
  INFO,
  EDITTRANSLATIONPROGRESS,
  SAMETEXT
} from "../Common/Constants/constants";
import CreateIcon from "@mui/icons-material/Create";
import { TLContext } from "../../Context";
import {
  AddTranslationBasedOnTransKey,
  GetTranslations,
  NewTranslations
} from "../../assets/APIInterface";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const EditTranslationpopup = (props) => {
  const { Update, EditTranslationData, EditTranslationOpen } = props;
  const {
    SelectedApplication,
    SetNotification,
    SetShowLinearProgress,
    SetTransTotalCount,
    SetTranslations,
    PageNumber,
    SearchQuery,
    RowPerPage
  } = useContext(TLContext) || {};
  const {
    TranslationKeyInput,
    EnglishInput,
    LangInputExisting,
    LangInputNew,
    AutoTransLateCheckBox,
    AutoTransLateTextSpan,
    AutoTranslateDiv,
    LangInputNewError
  } = useEditTranslationStyles();
  const [EditTransText, setEditTransText] = useState("");
  const [TransTextEdit, setTransTextEdit] = useState(false);
  const [AutoTranslate, setAutoTranslate] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [ErrorKey, setErrorKey] = useState(false);

  const setIsCheckedForAutoTranslate = () => setAutoTranslate(true);
  const createActionsButton = () => {
    return [
      {
        id: "Yes_Validatetag",
        name: "Validate",
        onClick: onValidate
      }
    ];
  };

  const onValidate = () => {
    if (EditTranslationData[0].Value !== EditTransText) {
      if (TransTextEdit && EditTransText != "") {
        SetShowLinearProgress(true);
        SetNotification({
          visible: true,
          statusType: INFO,
          notificationText: EDITTRANSLATIONPROGRESS,
          isAutohide: true,
          showProgress: false,
        });
        if (EditTranslationData[0].LanguageID != LANGENGLISH) {
          let Trans = {
            TransKey: EditTranslationData[0].TransKey,
            LanguageID: EditTranslationData[0].LanguageID,
            ProjectId: SelectedApplication,
            Value: EditTransText,
          };
          AddTranslationBasedOnTransKey(Trans, (trans) => {
            if (trans.status === SUCCESS) {
              reloadGrid();
              SetNotification({
                visible: true,
                statusType: SUCCESS,
                notificationText:
                  EditTranslationData[0].TransKey + UPDATETRANSLATIONMESSAGE,
                isAutohide: true,
                showProgress: false,
              });
              SetShowLinearProgress(false);
            } else {
              SetNotification({
                visible: true,
                statusType: ERROR,
                notificationText: trans.result,
                isAutohide: true,
                showProgress: false,
              });
              SetShowLinearProgress(false);
            }
            setAutoTranslate(false);
            setEditTransText("");
            setTransTextEdit(false);
            setErrorMessage("");
            setErrorKey(false);
            Update(false);
          });
        } else {
          if (AutoTranslate) {
            autoTranslateCheck();
          } else {
            autoTranslateNonCheck();
          }
        }
      } else {
        setErrorKey(true);
        setErrorMessage(VALIDATEMESSAGE);
      }
    } else {
      setErrorKey(true);
      setErrorMessage(SAMETEXT);
    }
  };

  const autoTranslateCheck = () => {
    let Trans = {
      Translations: {
        Key: EditTranslationData[0].TransKey,
        Translations: [
          {
            LanguageID: EditTranslationData[0].LanguageID,
            ProjectId: SelectedApplication,
            Value: EditTransText
          }
        ]
      }
    };
    NewTranslations(Trans, true,true, (trans) => {
      if (trans.status === SUCCESS) {
        SetNotification({
          visible: true,
          statusType: SUCCESS,
          notificationText:
            EditTranslationData[0].TransKey + UPDATETRANSLATIONMESSAGE,
          isAutohide: true,
          showProgress: false
        });
        reloadGrid();
        SetShowLinearProgress(false);
      } else {
        SetNotification({
          visible: true,
          statusType: ERROR,
          notificationText: trans.result,
          isAutohide: true,
          showProgress: false
        });
        SetShowLinearProgress(false);
      }
      setAutoTranslate(false);
      setEditTransText("");
      setTransTextEdit(false)
      setErrorMessage("");
      setErrorKey(false)
      Update(false);
    });
  };

  const autoTranslateNonCheck = () => {
    let Trans = {
      Translations: {
        Key: EditTranslationData[0].TransKey,
        Translations: [
          {
            LanguageID: EditTranslationData[0].LanguageID,
            ProjectId: SelectedApplication,
            Value: EditTransText
          }
        ]
      }
    };
    NewTranslations(Trans, false,true, (trans) => {
      if (trans.status === SUCCESS) {
        SetNotification({
          visible: true,
          statusType: SUCCESS,
          notificationText:
            EditTranslationData[0].TransKey + UPDATETRANSLATIONMESSAGE,
          isAutohide: true,
          showProgress: false
        });
        reloadGrid();
        SetShowLinearProgress(false);
      } else {
        SetNotification({
          visible: true,
          statusType: ERROR,
          notificationText: trans.result,
          isAutohide: true,
          showProgress: false
        });
        SetShowLinearProgress(false);
      }
      setAutoTranslate(false);
      setEditTransText("");
      setTransTextEdit(false)
      setErrorMessage("");
      setErrorKey(false)
      Update(false);
    });
  };

  const reloadGrid = () => {
    GetTranslations(
      SelectedApplication,
      PageNumber,
      SearchQuery,
      RowPerPage,
      (QueriedTranslations) => {
        if (QueriedTranslations.status === SUCCESS) {
          SetTranslations(QueriedTranslations.result);
          SetTransTotalCount(QueriedTranslations.totalCount);
        }
      }
    );
  };

  const handleApplicationName = ({ target: { value } }) => {
    let editTransValue = value.trim();
    setErrorMessage("");
    setErrorKey(false);
    setTransTextEdit(true);
    setEditTransText(editTransValue);
  };

  const onEditTranslationModalClose = () => {
    setEditTransText("");
    setTransTextEdit(false)
    setErrorMessage("");
    setErrorKey(false)
    Update(false);
  };
  const displayCurrentLang = () => {
    var displayCurrentLangText =
    CURRENT +
      " " +
      (EditTranslationData &&
        EditTranslationData[0].Language.charAt(0).toUpperCase() +
          EditTranslationData[0].Language.slice(1).toLowerCase()) +
      " " +
      TRANSLATION;
    return displayCurrentLangText;
  };

  const displayNewLang = () => {
    var displayNewLangText =
    NEW +
      " " +
      (EditTranslationData &&
        EditTranslationData[0].Language.charAt(0).toUpperCase() +
          EditTranslationData[0].Language.slice(1).toLowerCase()) +
      " " +
      TRANSLATION;
    return displayNewLangText;
  };

  const DisplayApplicationData = () => {
    return (
      EditTranslationData && (
        <div>
          <div>
            <TextField
              className={TranslationKeyInput}
              id="outlined-basic"
              label="Translation Key"
              size="small"
              multiline
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              value={EditTranslationData[0]?.TransKey || ""}
            />
          </div>
          <div>
            <TextField
              className={EnglishInput}
              multiline
              id="outlined-basic"
              label="English"
              size="small"
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              value={EditTranslationData[0]?.EngValue || ""}
            />
          </div>
          <div>
            {EditTranslationData[0].LanguageID === LANGENGLISH ||
            EditTranslationData[0].Value === "" ? (
              ""
            ) : (
              <TextField
                className={LangInputExisting}
                id="outlined-basic"
                label={displayCurrentLang()}
                size="small"
                autoFocus
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                multiline
                value={EditTranslationData[0]?.Value || ""}
              />
            )}
          </div>
          <div>
            <TextField
              className= {
                (ErrorKey ? LangInputNewError  :LangInputNew)
              }
              label={displayNewLang()}
              size="small"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              autoFocus
               multiline
              required
              error={ErrorKey}
              defaultValue={EditTranslationData[0]?.Value || ""}
              onChange={handleApplicationName}
            />
          </div>
          {EditTranslationData[0].LanguageID === LANGENGLISH ? (
            <div className={AutoTranslateDiv}>
              <FormControlLabel
                class={AutoTransLateTextSpan}
                control={
                  <Checkbox
                    onChange={setIsCheckedForAutoTranslate}
                    className={AutoTransLateCheckBox}
                    size="small"
                  />
                }
                label={ADDAUTOTRANSLATION}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      )
    );
  };
  return (
    <div>
      <CommonDialog
        open={EditTranslationOpen}
        translationData={DisplayApplicationData()}
        onEditTransModalClose={onEditTranslationModalClose}
        actionButtonList={createActionsButton()}
        title={EDITTRANSLATIONHEADER}
        headerIcon={CreateIcon}
        errorMsg={ErrorMessage}
      />
    </div>
  );
};
export default EditTranslationpopup;
