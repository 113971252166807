import { makeStyles } from "@mui/styles";

const applicationStyles = makeStyles((theme) => ({
  ApplicationDropDown: {
    display: "flex !important",
    flexDirection: "row !important",
    width: "192px !important",
    height: "40px !important",
    borderRadius: "4px !important",
    margin: "0px 16px 0px 0px !important",
   
    "& .MuiTextField-root": {
      width: "192px !important",
      marginLeft:"7px !importan"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "0px solid rgba(0, 0, 0, 0.42) !important"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "0px solid rgba(0, 0, 0, 0.42) !important"
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "2px !important"
    },
    "& .MuiOutlinedInput-root": {
      padding: "0px !important",
      background: "#FFFFFF !important",
      height: "40px",
    cursor: "pointer !important",
    },
    "& .MuiInputLabel-root": {
      top: "-6px !important",
      fontStyle: "normal !important",
      fontWeight: "normal !important",
      fontSize: "14px !important",
      color: "#000000 !important"
    },
    "& .MuiAutocomplete-input": {
      padding: "8.5px 4px 8.5px 6px !important",
      width: "134px !important",
      fontFamily: "Roboto !important",
      fontSize: "0px !important",
      borderRadius: "0% !important",
      maxWidth: "134px !important",
      height: "16px",
      display: "contents !important"
    },
    
    "& .MuiAutocomplete-listbox": {
      maxHeight: "0px !important"
    },
  },
 
  Paper: {
    width: "233px !important",
    fontSize: "14px !important",
    padding: "0px 0px 16px 8px !important",
    wordBreak: "break-word",

    "& .MuiAutocomplete-listbox": {
      maxHeight: "313px !important",
      lineHeight: "16px !important"
    },
    "& .MuiAutocomplete-option": {
      padding: "0px 0px 13px 2px !important",
      cursor: "pointer !important",
    },

    '& .MuiAutocomplete-option[aria-selected="true"]': {
      background: "#ffff !important",
      color: "#1891F6 !important"
    },
    "& .Mui-focused": {
      background: "#ffff !important"
    },
    "& .MuiAutocomplete-listbox li:first-child ": {
      borderBottom:"1px solid #E5E7ED !important",
      width:"202px  !important"
  }
  },
  Popper: {
    transform: "translateX(24px) translateY(87px) !important"
  },
  AddNewApplication: {
    width: "328px !important",
    background: "#F3FAFF !important",
    overflowY: "hidden !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px !important"
    },
    "& .MuiOutlinedInput-input": {
      position: "static",
      width: " 316px",
      height: "16px",
      left: "calc(50% - 316px/2)",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#152C73 !important",
      order: "1",
      alignSelf: "stretch",
      flexGrow: "0",
      margin: "12px 0px"
    },
    "& .MuiInputLabel-root": {
      top: "14px !important",
      color: "#617080 !important",
      lineHeight: "12px !important",
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "normal !important",
      fontSize: "10px !important"
    },
    "& .MuiOutlinedInput-root": {
      height: "40px !important"
    }
  },
  AddNewApplicationWithError: {
    width: "328px !important",
    background: "#F3FAFF !important",
    overflowY: "hidden !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px !important"
    },
    "& .MuiOutlinedInput-input": {
      position: "static",
      width: " 316px",
      height: "16px",
      left: "calc(50% - 316px/2)",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#152C73 !important",
      order: "1",
      alignSelf: "stretch",
      flexGrow: "0",
      margin: "12px 0px"
    },
    "& .MuiInputLabel-root": {
      top: "14px !important",
      color: "#617080 !important",
      lineHeight: "12px !important",
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "normal !important",
      fontSize: "10px !important"
    },
    "& .MuiOutlinedInput-root": {
      height: "40px !important"
    }
  },
  EditApplication: {
    width: "328px !important",
    background: "#F3FAFF !important",
    overflowY: "hidden !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px !important"
    },
    "& .MuiOutlinedInput-input": {
      position: "static",
      width: " 316px",
      left: "calc(50% - 316px/2)",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#152C73 !important",
      order: "1",
      alignSelf: "stretch",
      flexGrow: "0",
      margin: "12px 0px"
    },
    "& .MuiInputLabel-root": {
      top: "12px !important",
      color: "#617080 !important",
      lineHeight: "12px !important",
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "normal !important",
      fontSize: "10px !important",
      left:"-13px"
    },
    "& .MuiOutlinedInput-root": {
      padding:"0px"
    }
  },
  Icon: {
    cursor: "pointer !important",
    position: "relative",
    top: "7px",
    marginLeft: "-6px",
    marginRight:"4px"
  },
  
  SearchTextInput: {
    "& .MuiInput-input": {
      width: "169px !important",
      height: "16px !important",
      top: "4px !important",
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "normal !important",
      fontSize: "14px !important",
      lineHeight: "16px !important",
      display: "flex !important",
      alignItems: "center !important",
      cursor: "pointer !important",
    },
    
  },
  divEdit:{
    "& .EditIcon": {
      position: "relative !important",
      width: "14.93px !important",
      height: "18px !important",
      top: "5px !important",
      marginLeft: "-2px !important",
      cursor: "pointer !important",
    },
    display:"flex !important",
    '& .hidden' :{
      opacity: 0
    },
  },
  spanContentAddApp:{
    cursor: "pointer !important",
    fontWeight: "bold",
    fontFamily: 'Roboto',
    fontStyle: "normal"
  },
  spanContent:{
    cursor: "pointer !important",
    marginLeft: "11px",
    marginTop: "6px",
   
  },
  DivDeleteContent:{
    display:"flex",
    marginTop: "16px",
    justifyContent:"center"
  },
  SpanArrowDown:{
    marginLeft: "12px"
  }
}));

export default applicationStyles;
