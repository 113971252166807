import React, { useState, useContext } from "react";
import CommonDialog from "../../Common/Dialog/commonDialog";
import CreateIcon from "@mui/icons-material/Create";
import useApplicationStyles from "./ApplicationStyles";
import { TLContext } from "../../../Context";
import {
  EDITAPPHEADER,
  DELETEHEAPPHEADER,
  EDITAPPLICATIONPROGRESS,
  APPLICATIONPROGRESS,
  APPLICATIONEDITSUCCESS,
  APPLICATIONFAILURE,
  APPLICATIONVALIDATE,
  EDITAPPLABEL,
  ERROR,
  SUCCESS,
  INFO
} from "../../Common/Constants/constants";
import TextField from "@mui/material/TextField";
import { UpdateApplication } from "../../../assets/APIInterface";
import {
  validateFormFields,
  defaultValidation,
  defaultValidationMessages
} from "./../../../utilities/applicationValidateutils";
import DeleteApplication from "./DeleteApplication";
const EditApplication = (props) => {
  const {
    EditAppModalOpen,
    onEditAppModalClose,
    AppName,
    APPIndex,
    SelectedAppIndex
  } = props;
  const {
    SetNotification,
    Applications,
    SetApplications,
    SetShowLinearProgress,
    setIsLoading,
    setApp
  } = useContext(TLContext) || {};
  const { EditApplication } = useApplicationStyles();
  const [ErrorMessage, setErrorMessage] = useState("");
  const [ErrorState, setErrorState] = useState(false);
  const [ApplicationText, setEditApplicationText] = useState("");
  const [DeleteAppModalOpen, setDeleteAppModal] = useState(false);

  const createActionsButton = () => {
    return [
      {
        id: "Yes_Edittag",
        name: "Validate",
        onClick: onValidate
      }
    ];
  };

  const createDeleteActionsButton = () => {
    return [
      {
        id: "Yes_DeleteModaltag",
        name: "DeleteModal",
        onClick: onDeleteAppPopup
      }
    ];
  };

  const onValidate = () => {
    const validateMessage = validateFormFields(
      ApplicationText,
      defaultValidation,
      defaultValidationMessages
    );
    if (validateMessage === "") {
      setErrorState(false);
      setIsLoading(true);
      SetNotification({
        visible: true,
        statusType: INFO,
        notificationText: EDITAPPLICATIONPROGRESS,
        isAutohide: true,
        showProgress: true
      });
      let ApplicationsName = {
        Name: ApplicationText
      };
      UpdateApplication(APPIndex, ApplicationsName, (QueriedApplications) => {
        let updatedAppsList = [...Applications];
        if (QueriedApplications.status === SUCCESS) {
          SetNotification({
            visible: true,
            statusType:SUCCESS,
            notificationText: APPLICATIONEDITSUCCESS,
            isAutohide: true,
            showProgress: false
          });
          updatedAppsList.splice(SelectedAppIndex - 1, 1);
          updatedAppsList.push({
            id: APPIndex,
            Name: ApplicationText.trim()
          });
          SetApplications(updatedAppsList);
          setIsLoading(false);
          setEditApplicationText("");
          onEditAppModalClose(false,updatedAppsList.find((obj)=>obj.id===APPIndex));
        } else {
          if (
            QueriedApplications.status === ERROR &&
            QueriedApplications.result != ""
          ) {
            setErrorMessage(QueriedApplications.result);
            SetNotification({
              visible: true,
              statusType: ERROR,
              notificationText: APPLICATIONVALIDATE,
              isAutohide: true,
              showProgress: false
            });
            setIsLoading(false);
          } else {
            SetNotification({
              visible: true,
              statusType:ERROR,
              notificationText: APPLICATIONFAILURE,
              isAutohide: true,
              showProgress: false
            });
            setIsLoading(false);
          }
        }
      });
    } else {
      setErrorMessage(validateMessage);
      setErrorState(true);
    }
  };
  
  const onDeleteAppPopup = () => {
    setDeleteAppModal(true);
  };

  const onDeleteAppClose = () => {
    setErrorMessage("");
    setErrorState(false);
    setDeleteAppModal(false);
    onEditAppModalClose(false,Applications.find((obj)=>obj.id===APPIndex));
    setEditApplicationText("");
  };

  const onEditAppClose = () => {
     setErrorMessage("");
    setErrorState(false);
    onEditAppModalClose(false,Applications.find((obj)=>obj.id===APPIndex));
    setEditApplicationText("");
  };

  const handleApplicationName = ({ target: { value } }) => {
    setErrorMessage("");
    setErrorState(false);
    setEditApplicationText(value);
 };
  const DisplayApplicationData = () => {
    return (
      <div>
        <TextField
          className={EditApplication}
          id="editApplication"
          label={EDITAPPLABEL}
          variant="outlined"
          size="small"
          autoFocus
          InputLabelProps={{ shrink: false }}
          onChange={handleApplicationName}
          required
          multiline
          InputLabelProps={{ shrink: true }}
          defaultValue={AppName}
        />
      </div>
    );
  };
  return (
    <div>
      <CommonDialog
        open={EditAppModalOpen}
        translationData={DisplayApplicationData()}
        onEditTransModalClose={onEditAppClose}
        actionButtonList={createActionsButton()}
        title={EDITAPPHEADER}
        headerIcon={CreateIcon}
        actionButtonDeleteList={createDeleteActionsButton()}
        deleteTitle={DELETEHEAPPHEADER}
        errorMsg={ErrorMessage}
      />
      <DeleteApplication
        onDeleteAppClose={onDeleteAppClose}
        DeleteAppModalOpen={DeleteAppModalOpen}
        AppName={AppName}
        APPIndex={APPIndex}
        SelectedAppIndex={SelectedAppIndex}
        onEditAppModalClose={onEditAppModalClose}
      />
    </div>
  );
};
export default EditApplication;
