import { makeStyles } from "@mui/styles";
import { Paper } from "@mui/material";

const languageStyles = makeStyles((theme) => ({
  Icon: {
    fontSize: "small"
  },

  CheckedIcon: {
    fontSize: "small"
  },
  LanguageDropDown: {
    background: "#FFFFFF !important",
    display: "flex !important",
    flexDirection: "row !important",
    width: "149px!important",
    height: "40px !important",
    borderRadius: "4px !important",
    order: "0 !important",
    flexGrow: "0 !important",
    cursor: "pointer !important",
    pointerEvents: "auto",
    "& .MuiSelect-select": {
      width: "149px!important",
      height: "40px !important",
      padding: " 16.5px  0px 0.5px 40px !important",
      fontStyle: "normal !important",
      fontSize: "14px",
      "& .MuiSvgIcon-root": {
        transform: "translateY(-16px) !important"
      }
    },
  },
  Langli: {
    background : "#ffffff !important",
    padding: "0px 0px 5px 19px !important",
    whiteSpace: "normal !important",
    "& .MuiListItemText-primary" : {
      fontSize : "14px !important"
    },
    "& .MuiListItemIcon-root": {
      minWidth : "25px !important"
    }
  },
  LangSelectCheckBox: {
    paddingLeft: "0px !important",
    width: "18px !important",
    height: "18px !important",
    color: "#1891F6 !important"
  },
  LangSelectMenuInput: {
    "& .MuiTypography-root": {
      fontSize: "14px !important"
    }
  },
  DownArrow: {
    transform: "translateY(18px) !important"
  },
  DefaultPlaceholder: {
    width: "99px",
    opacity:"1.38 !important",
    height: "23px",
    fontFamily: "Roboto  !important",
    fontStyle: "normal !important",
    fontWeight: "bold !important",
    fontSize: "20px !important",
    lineHeight: "23px !important",
    color: "#617080 !important",
    margin: "10px 0px 15px !important",
    cursor:"default !Important",
    "&:hover":{
      background :"#ffffff !important"
    }
  },
  FlagImage: {
    borderRadius: "50%",
    position :"relative",
    right :"25px"
  },
  LangText : {
    position: "relative",
    bottom: "7px",
    right: "20px"
  },
  DefaultLang : {
    cursor : "not-allowed !important"
  },
  DisplayVal : {
    display : "none !important"
  }
}));

export default languageStyles;