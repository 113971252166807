import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import useSearchStyles from "./SearchStyles";
import { GetTranslations } from "../../../assets/APIInterface";
import { TLContext } from "../../../Context";
import { DEFAULTPAGENUMBER,SUCCESS,SEARCH,ENTER,CLICK  } from '../../Common/Constants/constants'
const Search = (props) => {
  const { SearchText, InputAdorment,SearchIconStyles} = useSearchStyles();
  const {
    SetTranslations,
    SelectedApplication,
    SearchQuery,
    SetSearchQuery,
    RowPerPage,
    SetPageNumber,
    SetTransTotalCount,
    setIsLoading,
    SetAddNewTranslation
  } = useContext(TLContext) || {};

  const onTextChange = ({ target: { value } }) => {
    SetAddNewTranslation(false);
    SetSearchQuery(value);
    SetPageNumber(DEFAULTPAGENUMBER);
    if (!value) {
      
      resetTranslationGrid(value);
    }
  };
  const resetTranslationGrid = (value) => {
    setIsLoading(true);
    GetTranslations(
      SelectedApplication ?SelectedApplication :  DEFAULTPAGENUMBER,
      DEFAULTPAGENUMBER,
      value,
      RowPerPage,
      (QueriedTranslations) => {
        if(QueriedTranslations.status === SUCCESS){
          SetTranslations(QueriedTranslations.result)
          SetTransTotalCount(QueriedTranslations.totalCount);
          setIsLoading(false);
      }
      else{
        setIsLoading(false);
      }}
    );
  };
  const searchResultCheck = ({  key, type  }) => {
    
    if (key === ENTER || type === CLICK) {
      setIsLoading(true);
      GetTranslations(
        SelectedApplication  ?SelectedApplication :  DEFAULTPAGENUMBER,
        DEFAULTPAGENUMBER,
        SearchQuery,
        RowPerPage,
        (QueriedTranslations) => {
          if(QueriedTranslations.status === SUCCESS){
            SetTranslations(QueriedTranslations.result)
            SetTransTotalCount(QueriedTranslations.totalCount);
            setIsLoading(false);
        }
        else{
          setIsLoading(false);
        }}
      );
    }
  };

  return (
    <TextField
      id="search-lang"
      className={SearchText}
      onChange={onTextChange}
      onKeyDown={(event) => {
        searchResultCheck(event);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment className={InputAdorment} position="start">
            <IconButton
              type="submit"
              onClick={searchResultCheck}
            >
              <SearchIcon className={SearchIconStyles} />
            </IconButton>
          </InputAdornment>
        )
      }}
      variant="standard"
      placeholder={SearchQuery ? "" : SEARCH}
      value={SearchQuery}
    ></TextField>
  );
};
export default Search;
