import { appConfig } from "../Environment/environments";

const {
  api: { apiUrl },
} = appConfig;

const GetLanguages = (callback) => {
  fetch(apiUrl + "languages", {
    method: "GET",
    headers: APIHeader(),
  })
    .then((res) => res.json())
    .then((json) => {
      callback(json);
    })
    .catch((error) => {
      callback(error);
    });
}

const GetTranslations = (
  ProjectId,
  PageNumber,
  SearchQuery,
  RowPerPage,
  callback
) => {
  fetch(
    apiUrl +
      "translations/getAllFromProjectID/" +
      ProjectId +
      "?pageNumber=" +
      PageNumber +
      "&searchText=" +
      SearchQuery +
      "&rowsPerPage=" +
      RowPerPage,
    {
      method: "GET",
      headers: APIHeader(),
    }
  )
    .then((res) => res.json())
    .then((json) => {
      callback(json);
    })
    .catch((error) => {
      callback(error);
    });
}

const GetProjects = (callback) =>{
  fetch(apiUrl + "projects", {
    method: "GET",
    headers: APIHeader(),
  })
    .then((res) => res.json())
    .then((json) => {
      callback(json);
    })
    .catch((error) => {
      callback(error);
    });
}

const UpdateTranslation =(Translation, callback) =>{
  fetch(apiUrl + "translations/" + Translation.id, {
    method: "PUT",
    headers: APIHeader(),
    body: JSON.stringify(Translation),
  })
    .then((res) => res.json())
    .then((json) => {
      callback(json.result);
    })
    .catch((error) => {
      callback(error);
    });
}

const NewTranslations = (Translations, autoTranslate,IsEdited, callback) =>{
  let url = apiUrl + "translations";
  if (autoTranslate && IsEdited===false)
  {
    url = url + "?autoTranslate=1&NewKey=1";
  }else{
    url = autoTranslate?  url + "?autoTranslate=1": url
  }
  
  fetch(url, {
    method: "POST",
    headers: APIHeader(),
    body: JSON.stringify(Translations),
  })
    .then((res) => res.json())
    .then((json) => {
      callback(json);
    })
    .catch((error) => {
      callback(error);
    });
}

const AddTranslationBasedOnTransKey = (Translation, callback) =>{
  fetch(apiUrl + "translations/addTranslationBasedOnTransKey", {
    method: "POST",
    headers: APIHeader(),
    body: JSON.stringify(Translation),
  })
    .then((res) => res.json())
    .then((json) => {
      callback(json);
    })
    .catch((error) => {
      callback(error);
    });
}

const AddApplication = (Application, callback) =>{
  fetch(apiUrl + "projects", {
    method: "POST",
    headers: APIHeader(),
    body: JSON.stringify(Application),
  })
    .then((res) => res.json())
    .then((json) => {
      callback(json);
    })
    .catch((error) => {
      callback(error);
    });
}

const UpdateApplication = (ApplicationId, Application, callback) =>{
  fetch(apiUrl + "projects/" + ApplicationId, {
    method: "PUT",
    headers: APIHeader(),
    body: JSON.stringify(Application),
  })
    .then((res) => res.json())
    .then((json) => {
      callback(json);
    })
    .catch((error) => {
      callback(error);
    });
}

const DeleteApplicationBasedOnProjectId = (ApplicationId, callback) =>{
  fetch(apiUrl + "projects/" + ApplicationId, {
    method: "DELETE",
    headers: APIHeader(),
  })
    .then((res) => res.json())
    .then((json) => {
      callback(json);
    })
    .catch((error) => {
      callback(error);
    });
}

const DeleteTranslationBasedOnTransKey = (Translations, callback) =>{
  let url = apiUrl + "translations";
  fetch(url, {
    method: "DELETE",
    headers: APIHeader(),
    body: JSON.stringify(Translations),
  })
    .then((res) => res.json())
    .then((json) => {
      callback(json);
    })
    .catch((error) => {
      callback(error);
    });
}

const AddBulkTranslation = (ProjectId,LangId,formData, callback, uri) =>{
  fetch(apiUrl + `translations/${uri}/`+ProjectId+"/"+LangId, {
    method: "POST",
    headers: APIHeaderForBulk(),
    body: formData,
  })
    .then((res) => res.json())
    .then((json) => {
      callback(json);
    })
    .catch((error) => {
      callback(error);
    });
}

const GetAllTranslationsByProjectId = (formData, callback) => {
  fetch(apiUrl + "translations/getAllFromProjectIdandLanguageIds/", {
    method: "POST",
    headers: APIHeader(),
    body: JSON.stringify(formData),
  })
    .then((res) => res.json())
    .then((json) => {
      callback(json);
    })
    .catch((error) => {
      callback(error);
    });
}

const GetAllAutoTranslationsByProjectId = (ProjectId, callback) => {
  fetch(apiUrl + "translations/getAutoTranslated/" + ProjectId, {
    method: "GET",
    headers: APIHeader(),
  })
    .then((res) => res.json())
    .then((json) => {
      callback(json);
    })
    .catch((error) => {
      callback(error);
    });
}

export {
  GetLanguages,
  GetTranslations,
  GetProjects,
  NewTranslations,
  UpdateTranslation,
  AddTranslationBasedOnTransKey,
  AddApplication,
  UpdateApplication,
  DeleteApplicationBasedOnProjectId,
  DeleteTranslationBasedOnTransKey,
  AddBulkTranslation,
  GetAllTranslationsByProjectId,
  GetAllAutoTranslationsByProjectId
};

function APIHeader() {
  return new Headers({
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("msal.idtoken"),
  });
}
function APIHeaderForBulk() {
  return new Headers({
    Authorization: "Bearer " + localStorage.getItem("msal.idtoken"),
  });
}
