import React from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import Button from "@mui/material/Button";
import "../../componentStyles.css";
import { getImageDetails } from "../../../utilities/imagesutils";
const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: "90vh !important",
    padding: "16px 24px"
  },
  root: {
    padding: "0px 0px !important",
    marginTop: "32px"
  },
  closeBtn: {
    display: "none !important"
  },
  iconButtonRoot: {
    justifyContent: "center !important",
    padding: "4px !important"
  },
  footerButton: {
    fontSize: "1rem",
    lineHeight: "1.2rem",
    padding: "9px 24px",
    textTransform: "none",
    border: "1px solid rgba(0, 0, 0, 0.23)"
  }
}));

const CommonDialog = (props) => {
  const {
    dialogClassName = null,
    contentClassName,
    translationData,
    title,
    footerClassName,
    errorMsg = "",
    footerComponent = null,
    hideCancel,
    cancelDisabled,
    actionButtonList = [],
    actionButtonDeleteList = [],
    headerIcon,
    deleteTitle
  } = props;
  const cancelText = "Cancel";
  const onClose = () => {
    props.onEditTransModalClose(false);
  };
  const classes = useStyles();
  const onCancelButtonClick = (event) => {
    props.onEditTransModalClose(false);
  };
  const actionButtonClickHandler = (event, button) => {
    !button.disabled && button.onClick(event);
  };
  return (
    <Dialog
      id="deleteTranslationId"
      open={props.open}
      onClose={onClose}
      scroll="paper"
      classes={{ paper: classes.paper, paperScrollPaper: dialogClassName }}
    >
      <div className="customModalHeader">
        {headerIcon &&
          React.createElement(headerIcon, {
            className: "customModalHeaderIcon"
          })}
        <span className="customModalTitle">{title}</span>
        <IconButton
          classes={{ root: classes.closeBtn }}
          onClick={onCancelButtonClick}
        >
          <CloseIcon
            fontSize="small"
            className="customModalCloseIcon"
            titleAccess="close"
          />
        </IconButton>
      </div>
      <DialogContent
        classes={{ root: classes.root }}
        className={contentClassName}
      >
        {translationData}
      </DialogContent>
      <div className={footerClassName || "customModalDialogFooter"}>
        {errorMsg && (
          <div className="customModalDialogError">
            <ErrorIcon
              fontSize="small"
              className="customModalCloseIcon customModalRightMargin"
            />
            {errorMsg}
          </div>
        )}
        <DialogActions classes={{ root: classes.iconButtonRoot }}>
          {!footerComponent ? (
            <>
              {!hideCancel && (
                <Button
                  classes={{ root: classes.footerButton }}
                  id="customModalCancel"
                  variant="outlined"
                  size="large"
                  name={cancelText}
                  onClick={onCancelButtonClick}
                  disabled={cancelDisabled}
                >
                  {cancelText}
                </Button>
              )}
              {actionButtonList.length > 0 &&
                actionButtonList.map((actionButton) => {
                  return (
                    <Button
                      classes={{ root: classes.footerButton }}
                      size="large"
                      variant="contained"
                      color="primary"
                      key={actionButton.id}
                      id={actionButton.id}
                      name={actionButton.name}
                      onClick={(event) =>
                        actionButtonClickHandler(event, actionButton)
                      }
                      disabled={actionButton.disabled}
                    >
                      {actionButton.name}
                    </Button>
                  );
                })}
            </>
          ) : (
            footerComponent
          )}
        </DialogActions>
        {actionButtonDeleteList.length > 0 ? (
          <div className="divDeleteFooter">
            <span>
              {actionButtonDeleteList.length > 0 &&
                actionButtonDeleteList.map((actionButton, index) => {
                  return (
                    <img key={index} src={getImageDetails("DeleteRed").img} className="customModalDeleteIcon" alt="" onClick={(event) =>
                      actionButtonClickHandler(event, actionButton)
                    }>
                    </img>
                  );
                })}
            </span>
            <span className="customModalDeleteHeader">{deleteTitle}</span>
          </div>
        )
          :
          ""
        }

      </div>
    </Dialog>
  );
};

export default CommonDialog;
