import React, {  useContext } from "react";
import CommonDialog from "../../Common/Dialog/commonDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { TLContext } from "../../../Context";
import useApplicationStyles from "./ApplicationStyles";
import {
  DELETEHEAPPHEADER,
  DELETEAPPMESSAGE,
  DELETEAPPMESSAGECONTINUE,
  DELETEMESSAGESUCCESS,
  DELETEAPPLICATIONPROGRESS,
  DELETEVALIDATE,
  DELETEFAILURE,
  INFO,
  SUCCESS,
  ERROR
} from "../../Common/Constants/constants";
import { DeleteApplicationBasedOnProjectId } from "../../../assets/APIInterface";
const DeleteApplication = (props) => {
  const { AppName, DeleteAppModalOpen, APPIndex, onDeleteAppClose,SelectedAppIndex } = props;
  const {
    SetNotification,
    setIsLoading,
    Applications,
    SetApplications,
    SelectedApplication
  } = useContext(TLContext) || {};
  const { DivDeleteContent } = useApplicationStyles();

  const createActionsButton = () => {
    return [
      {
        id: "Yes_Deletetag",
        name: "Delete",
        onClick: onDelete
      }
    ];
  };

  const onDelete = () => {
    setIsLoading(true);
    SetNotification({
      visible: true,
      statusType: INFO,
      notificationText: DELETEAPPLICATIONPROGRESS,
      isAutohide: true,
      showProgress: true
    });
    DeleteApplicationBasedOnProjectId(APPIndex, (QueriedApplications) => {
      let updatedAppsList = [...Applications];
      if (QueriedApplications.status === SUCCESS) {
        SetNotification({
          visible: true,
          statusType: SUCCESS,
          notificationText: DELETEMESSAGESUCCESS,
          isAutohide: true,
          showProgress: false
        });
        
        updatedAppsList.splice(SelectedAppIndex - 1, 1);
        if(APPIndex===SelectedApplication)
        {
          reloadPage()
        }
        SetApplications(updatedAppsList);
        setIsLoading(false);
        onDeleteAppClose(false);
        
      } else {
        if (
          QueriedApplications.status === ERROR &&
          QueriedApplications.result != ""
        ) {
         
          SetNotification({
            visible: true,
            statusType: ERROR,
            notificationText: DELETEVALIDATE,
            isAutohide: true,
            showProgress: false
          });
          setIsLoading(false);
        } else {
          SetNotification({
            visible: true,
            statusType: ERROR,
            notificationText: DELETEFAILURE,
            isAutohide: true,
            showProgress: false
          });
          setIsLoading(false);
        }
      }
    });
  };

  const reloadPage = () => {
    window.location.reload();
  };

  function onDeleteAppModalClose() {
    onDeleteAppClose(false);
  }

  const DeleteApplicationData = () => {
    return (
      <div>
        <div className="confirmContent">
          <span>{DELETEAPPMESSAGE}</span>
        </div>
        <div className="confirmContentCenterAlign">
          <span>
            <b>{AppName}</b>
          </span>
        </div>
        <div className={DivDeleteContent}>
          <span>{DELETEAPPMESSAGECONTINUE}</span>
        </div>
      </div>
    );
  };
  return (
    <CommonDialog
      open={DeleteAppModalOpen}
      translationData={DeleteApplicationData()}
      onEditTransModalClose={onDeleteAppModalClose}
      actionButtonList={createActionsButton()}
      title={DELETEHEAPPHEADER}
      headerIcon={DeleteIcon}
    />
  );
};
export default DeleteApplication;
