import { makeStyles } from "@mui/styles";

const headerStyles = makeStyles((theme) => ({
  MuiAvatarroot: {
    height: "30px !important",
    width: "30px !important",
    minWidth: "30px !important",
    color: "#ffffff !important",
    background: "#1891f6 !important",
    fontStyle: "normal !important",
    fontWeight: "normal !important",
    borderRadius: "50% !important",
    fontSize: "12px !important",
    lineHeight: "32px !important",
  },
  Header: {
    display: "flex",
    alignItems: "center",
    padding: "0px 20px 0px 24px",
    background:
      "radial-gradient(52.63% 300.89% at 49.31% 251.79%,#1891f6 0%,#15205e 100%)",
    height: "56px",
  },
  Wrapper: {
    display: "flex",
    alignItems: "center",
    height: "56px",
  },
  Container: {
    width: "100%",
  },

  AddUser: {
    color: "#ffffff",
    alignItems: "center",
    transform: "scale(-1,1)",
    marginRight: "41px",
  },

  BrandSize: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 24px 0px 0px",
    height: "55px",
    borderRight: "1px solid #ffffff !important",
    cursor :"pointer"
  },

  HeaderTitle: {
    width: "147px !important",
    height: "19px !important",

    alignItems: "center",
    color: "#fff !important",
    fontStyle: "normal !important",
    fontWeight: "normal !important",
    fontSize: "16px !important",
    lineHeight: "19px !important",
    padding: "0px 16px",
  },

  UserIconAndInitials: {
    display: "flex !important",
    flex: "1 1",
    justifyContent: "end !important",
    alignItems: "center !important",
    height: "30px !important",
  },
}));
export default headerStyles;
