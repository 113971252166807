import React, { useContext, useState, useEffect, useRef } from "react";
import { TLContext } from "../../Context";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import dataGridStyles from "./translationGridStyles";
import DeleteTranslation from "./deleteTranslation";
import {
  TRANSLATIONKEY,
  ITEMSPERPAGE,
  ROWSPERPAGE,
  NORECORDS,
  DEFAULTPAGENUMBER,
  DEFAULTERRORMESSAGE,
  ADDTRANSLATIONPLACEHOLDER,
  DEFAULTROWNUMBER,
  SUCCESS,
  DEFAULTLANGID
} from "../Common/Constants/constants";
import TranslationGridPagination from "../Common/TranslationGrid/translationGridPagination";
import { GetTranslations } from "../../assets/APIInterface";
import AddTranslation from "./addTranslation";
import { getImageDetails } from "../../utilities/imagesutils";
import LinearProgress from "@mui/material/LinearProgress";
import EditTranslationpopup from "./editTranslation";

const TranslationGrid = (props) => {
  const {
    Languages,
    Translations,
    SetTranslations,
    SelectedApplication,
    SearchQuery,
    TransTotalCount,
    isLoading,
    RowPerPage,
    SetRowPerPage,
    PageNumber,
    SetPageNumber,
    AddNewTranslation,
    SetAddNewTranslation,
    showLinearProgress,
    showGrid,
    Applications,
    SetShowLinearProgress,
    setScrollGrid,
    scrollGrid,
    writePermission
  } = useContext(TLContext) || {};

  const [DeleteTransModalOpen, SetDeleteTransModalOpen] = useState(false);
  const [transData, setTransData] = React.useState("");
  const [transIndex, setTransIndex] = useState("");

  const [page, setPage] = useState(DEFAULTPAGENUMBER);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULTROWNUMBER);
  const [EditTranslationOpen, SetEditTranslationOpen] = useState(false);
  const [EditTranslationData, SetEditTranslationData] = useState();

  const { genericMsg, gridContainer } = dataGridStyles();
  const [showActionId, setShowActionId] = useState(-1);
  const containerRef = useRef();
  useEffect(() => {
    createColumns();
    setTransData(Translations);
    containerRef.current.scrollTo({
      top: 0,
      left: 0,
      behaviour: "smooth"
    });
  }, [
    Translations,
    Languages,
    TransTotalCount,
    SetRowPerPage,
    RowPerPage,
    SetPageNumber,
    PageNumber,
    AddNewTranslation,
  ]);
  const createColumns = () => {
    const cell = [];
    cell.push(<TableCell></TableCell>);
    cell.push(
      <TableCell>
        <img
          className="langImg"
          src={getImageDetails("TranslationKey").img}
          alt=""
        />
        {TRANSLATIONKEY}
      </TableCell>
    );
    Languages.map((header, index) => {
      const language = header.Name.charAt(0).toUpperCase() + header.Name.slice(1).toLowerCase();
      cell.push(
        <TableCell key={index}>
          <img
            className="langImg"
            src={getImageDetails(header.Name).img}
            alt=""
          />
          {language}
        </TableCell>
      );
    });
    return cell;
  };

  const deleteTransRow = (index, Trans) => {
    setTransIndex(index);
    SetDeleteTransModalOpen(true);
    setTransData(Trans);
  };

  const onDeleteTransModalClose = () => {
    setTransIndex("");
    SetDeleteTransModalOpen(false);
    setTransData("");
  };

  const handleChangePage = (newPage) => {
    SetShowLinearProgress(true);
    setPage(newPage + 1);
    SetPageNumber(newPage + 1);
    SetRowPerPage(rowsPerPage);
    SetAddNewTranslation(false);
    GetTranslations(
      SelectedApplication === undefined ? "1" : SelectedApplication,
      newPage + 1,
      SearchQuery,
      rowsPerPage,
      (QueriedTranslations) => {
        if (QueriedTranslations.status === SUCCESS) {
          SetTranslations(QueriedTranslations.result);
          SetShowLinearProgress(false);
        } else {
          SetShowLinearProgress(false);
        }
      }
    );
  };

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    SetShowLinearProgress(true);
    if (typeof value === "string" && value.toLowerCase() === "all") {
      value = Translations.length;
    }
    SetPageNumber(DEFAULTPAGENUMBER);
    SetRowPerPage(parseInt(value, 10));
    setRowsPerPage(parseInt(value, 10));
    SetAddNewTranslation(false);
    GetTranslations(
      SelectedApplication === undefined ? "1" : SelectedApplication,
      DEFAULTPAGENUMBER,
      SearchQuery,
      parseInt(value, 10),
      (QueriedTranslations) => {
        if (QueriedTranslations.status === SUCCESS) {
          SetTranslations(QueriedTranslations.result);
          SetShowLinearProgress(false);
        } else {
          SetShowLinearProgress(false);
        }
      }
    );
  };

  const handelRowEdit = (id, TranslationsRow) => {
    SetAddNewTranslation(false);
    if (TranslationsRow.length != 0) {
      const selectedTransRow = Languages.find((lang) => lang.id === id);
      const defaultEngValue = TranslationsRow.find(
        (trans) => trans.LanguageID === DEFAULTLANGID
      );
      SetEditTranslationOpen(true);
      SetEditTranslationData(
        valuesforDisplay(selectedTransRow, defaultEngValue, TranslationsRow)
      );
    }
  };
  const valuesforDisplay = (selectedTransRow, defaultEngValue, TranslationsRow) => {
    return [
      {
        EngValue: defaultEngValue ? defaultEngValue.Value : "",
        Language: selectedTransRow.Name,
        LanguageID: selectedTransRow.id,
        Value:
          TranslationsRow.find(
            (transValue) => transValue.LanguageID === selectedTransRow.id
          )?.Value || "",
        TransKey: TranslationsRow ? TranslationsRow[0].TransKey : defaultEngValue.TransKey
      }
    ];
  };
  const handleUpdate = (newtext) => {
    SetEditTranslationOpen(newtext);
  };

  const tableScroll = () => {
    setScrollGrid(false);
  }

  return showGrid ? (
    <div className={gridContainer}>
      <TableContainer component={Paper}>
        <div className="messageContainer">
          <div id="customGridNoRecodsFound">
            <span>{DEFAULTERRORMESSAGE}</span>
          </div>
        </div>
      </TableContainer>
    </div>
  ) : (
    <div className={gridContainer}>
      <TableContainer component={Paper}>
        {showLinearProgress && <LinearProgress />}
        <div className="tableWrapper" ref={containerRef} id="translationTable" onScroll={() => tableScroll()}>
          <Table stickyHeader >
            <TableHead>
              <TableRow>{Languages.length != 0 && createColumns()}</TableRow>
            </TableHead>
            <TableBody>
              {AddNewTranslation && <AddTranslation />}
              {Translations.length != 0 &&
                Translations.map((TranslationsRow, index) => (
                  <TableRow
                    key={index}
                    onMouseLeave={() => {
                      setShowActionId(-1);
                    }}
                  >
                    <TableCell>
                      <img
                        className={
                          "deleteImg " +
                          (index === showActionId && writePermission ? "show" : "hidden")
                        }
                        src={getImageDetails("Delete").img}
                        alt=""
                        onClick={() =>
                          writePermission && deleteTransRow(index, TranslationsRow.TransKey)
                        }
                      />
                    </TableCell>
                    <TableCell
                      onMouseEnter={() => {
                        setShowActionId(-1);
                        setShowActionId(index);
                      }}
                    >
                      {TranslationsRow.TransKey}
                    </TableCell>
                    {Languages.map((Lang, i) => {
                      let Trans = TranslationsRow.Translations.find((trans) => trans.LanguageID === Lang.id) || { AutoTranslated: false, LanguageID: Lang.id, TransKey: TranslationsRow.TransKey };
                      return (
                        <TableCell
                          key={i}
                          scope="row"
                          onMouseEnter={() => {
                            setShowActionId(-1);
                          }}
                          className={`rowEdit ${writePermission ? '' : 'disableCursor'}`}
                          onClick={() => {
                            if (writePermission) {
                              handelRowEdit(
                                Lang.id,
                                TranslationsRow.Translations
                              );
                            }
                          }}
                        >
                          {Trans && Trans.Value ? (
                            Trans.AutoTranslated === true ? (
                              <div className="autotranslate">
                                <img
                                  class="autotranslateImg"
                                  src={getImageDetails("AutoTranslate").img}
                                />
                                <span className="transValue">{Trans.Value}</span>
                              </div>
                            ) : (
                              Trans.Value
                            )
                          ) : (
                            <div
                              className="transText"
                              data-ph={ADDTRANSLATIONPLACEHOLDER}
                            ></div>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {(Applications.length !== 0 || Languages.length !== 0) &&
            Translations.length === 0 && (
              <div className="messageContainer">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <div id="customGridNoRecodsFound">
                    <span>{NORECORDS}</span>
                  </div>
                )}
              </div>
            )}
        </div>
      </TableContainer>
      {(Applications.length !== 0 || Languages.length !== 0) && (
        <TranslationGridPagination
          labelRowsPerPage={ITEMSPERPAGE}
          rowsPerPageOptions={ROWSPERPAGE}
          component="div"
          rowsLength={TransTotalCount}
          rowsPerPage={RowPerPage}
          page={PageNumber - 1}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <DeleteTranslation
        onDeleteTransModalClose={onDeleteTransModalClose}
        Trans={transData}
        DeleteTransModalOpen={DeleteTransModalOpen}
        TransIndex={transIndex}
      />
      <EditTranslationpopup
        Update={handleUpdate}
        EditTranslationOpen={EditTranslationOpen}
        EditTranslationData={EditTranslationData}
      />
    </div>
  );
};

export default TranslationGrid;
