import enImg from "./../assets/images/lang/EN-US.svg";
import delImg from "./../assets/images/delete.svg";
import editImg from "./../assets/images/edit.svg";
import engImg from "./../assets/images/lang/ic-en.svg";
import deImg from "./../assets/images/lang/ic-de.svg";
import frImg from "./../assets/images/lang/ic-fr.svg";
import esImg from "./../assets/images/lang/ic-es.svg";
import itImg from "./../assets/images/lang/ic-it.svg";
import nlImg from "./../assets/images/lang/ic-nl.svg";
import plImg from "./../assets/images/lang/ic-pl.svg";
import ruImg from "./../assets/images/lang/ic-ru.svg";
import trImg from "./../assets/images/lang/ic-tr.svg";
import chImg from "./../assets/images/lang/ic-cn.svg";
import ptImg from "./../assets/images/lang/ic-pt.svg";
import svImg from "./../assets/images/lang/ic-sv.svg";
import jaImg from "./../assets/images/lang/Country=ic-jp.svg";
import transkeyImg from "./../assets/images/transkey.svg";
import carrierLogo from "./../assets/images/carrier.svg";
import delImgRed from "./../assets/images/deleteRed.svg";
import addapp from "./../assets/images/addApplication.svg";
import arrowDown from "./../assets/images/arrowDown.svg";
import autoTranslate from "./../assets/images/autoTranslate.svg";

export const ImgOpt = [
  {
    name: "English",
    lang: "en",
    leafLocale: "en",
    fullLangCode: "en-US",
    img: enImg,
  },
  {
    name: "Dutch",
    lang: "nl",
    leafLocale: "nl",
    fullLangCode: "nl-NL",
    img: nlImg,
  },
  {
    name: "English",
    lang: "en",
    leafLocale: "en",
    fullLangCode: "en-US",
    img: engImg,
  },
  {
    name: "French",
    lang: "fr",
    leafLocale: "fr",
    fullLangCode: "fr-FR",
    img: frImg,
  },
  {
    name: "German",
    lang: "de",
    leafLocale: "de",
    fullLangCode: "de-DE",
    img: deImg,
  },
  {
    name: "Italian",
    lang: "it",
    leafLocale: "it",
    fullLangCode: "it-IT",
    img: itImg,
  },
  {
    name: "Russian",
    lang: "ru",
    leafLocale: "ru",
    fullLangCode: "ru-RU",
    img: ruImg,
  },
  {
    name: "Polish",
    lang: "pl",
    leafLocale: "pl",
    fullLangCode: "pl-PL",
    img: plImg,
  },
  {
    name: "Turkish",
    lang: "tr",
    leafLocale: "tr",
    fullLangCode: "tr-TR",
    img: trImg,
  },
  {
    name: "Simplified chinese",
    lang: "zh",
    leafLocale: "zh",
    fullLangCode: "zh-CN",
    img: chImg,
  },
  {
    name: "Spanish",
    lang: "es",
    leafLocale: "es",
    fullLangCode: "es-ES",
    img: esImg,
  },
  {
    name: "traditional chinese",
    lang: "zh",
    leafLocale: "zht",
    fullLangCode: "zh-TW",
    img: chImg,
  },
  {
    name: "portuguese",
    lang: "pt",
    leafLocale: "pt",
    fullLangCode: "pt",
    img: ptImg,
  },
  {
    name: "swedish",
    lang: "sv",
    leafLocale: "sv",
    fullLangCode: "sv",
    img: svImg,
  },
  {
    name: "japanese",
    lang: "ja",
    leafLocale: "ja",
    fullLangCode: "ja",
    img: jaImg,
  },
  {
    name: "Delete",
    img: delImg,
  },
  {
    name: "Edit",
    img: editImg,
  },
  {
    name: "TranslationKey",
    img: transkeyImg,
  },
  {
    name: "CarrierLogo",
    img: carrierLogo,
  },
  {
    name: "DeleteRed",
    img: delImgRed,
  },
  {
    name: "AddApp",
    img: addapp,
  },
  {
    name: "ArrowDown",
    img: arrowDown,
  },
  {
    name: "AutoTranslate",
    img: autoTranslate
  }
];

export const getImageDetails = (img) => {
  let imageDetails = {};
  if (!img || typeof img !== "string") {
    return imageDetails;
  }

  for (const opt of ImgOpt) {
    if (opt.name.toUpperCase() === img.toUpperCase()) {
      imageDetails = opt;
      break;
    }
  }
  return imageDetails;
};
